import React, { useState } from "react";
import {
  Button,
  Menu,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Select,
  InputLabel,
  FormControl,
  Box,
  Typography,
  useTheme,
} from "@mui/material";
import { FaEllipsisV } from "react-icons/fa";

const CustomMenuButton = ({ menuinfo }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [viewOpen, setViewOpen] = useState(false);
  const [updateStatusOpen, setUpdateStatusOpen] = useState(false);
  const [status, setStatus] = useState("");
  const theme = useTheme();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleView = () => {
    setViewOpen(true);
    handleClose();
  };

  const handleUpdateStatusClick = () => {
    setUpdateStatusOpen(true);
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDialogClose = () => {
    setViewOpen(false);
    setUpdateStatusOpen(false);
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleUpdateStatus = () => {
    // Handle the status update logic here
    console.log("Status updated to:", status);
    setUpdateStatusOpen(false);
  };

  return (
    <>
      <FaEllipsisV
        size={20}
        style={{ cursor: "pointer" }}
        onClick={handleClick}
      />
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleView}>View</MenuItem>
        <MenuItem onClick={handleUpdateStatusClick}>Update Status</MenuItem>
      </Menu>

      <Dialog open={viewOpen} onClose={handleDialogClose}>
        <DialogTitle>Applicant Information</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              padding: 2,
              backgroundColor: theme.palette.background.paper,
              borderRadius: 1,
              boxShadow: theme.shadows[3],
            }}
          >
            <Typography variant="h6" gutterBottom>
              Applicant Details
            </Typography>
            <DialogContentText>
              <strong>Applicant Name:</strong> {menuinfo.applicantName}
            </DialogContentText>
            <DialogContentText>
              <strong>Village Name:</strong> {menuinfo.villageName}
            </DialogContentText>
            <DialogContentText>
              <strong>Block Name:</strong> {menuinfo.blockName}
            </DialogContentText>
            <DialogContentText>
              <strong>District:</strong> {menuinfo.district}
            </DialogContentText>
            <DialogContentText>
              <strong>Date:</strong> {menuinfo.date}
            </DialogContentText>
            <DialogContentText>
              <strong>Status:</strong> {menuinfo.status}
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={updateStatusOpen} onClose={handleDialogClose}>
        <DialogTitle>Update Status</DialogTitle>
        <DialogContent>
          <FormControl fullWidth variant="standard" margin="dense">
            <InputLabel id="status-label">Status</InputLabel>
            <Select
              labelId="status-label"
              id="status"
              value={status}
              onChange={handleStatusChange}
              label="Status"
            >
              <MenuItem value="Pending">Pending</MenuItem>
              <MenuItem value="Approved">Approved</MenuItem>
              <MenuItem value="Rejected">Rejected</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="secondary">Cancel</Button>
          <Button onClick={handleUpdateStatus} color="primary">Update</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CustomMenuButton;
