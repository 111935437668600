import { Button, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Divider } from "@mui/material";
import { AiFillFlag } from "react-icons/ai";
import DashboardLayout from "../../layouts";
import countryStyles from "../../styles/pages/CountryPage/index.style";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomInputAndSelectField from "../../components/CustomInputAndSelectField/Index";
import axios from "axios";
import endPoints from "../../config/endPoint";
import { convertFromRaw, EditorState, convertToRaw } from "draft-js";
import { storage } from "../../firebase_config";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router";
import { Avatar } from "@mui/material";
import TimePicker from "react-time-picker";
import { FaTimes, FaUserFriends } from "react-icons/fa";
import CustomButton from "../../components/CustomButton/Index";
import "../../styles/DescriptionStyle.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import { MdLogout } from "react-icons/md";
import { useLocation } from "react-router-dom";
import {
  OutlinedInput,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  SelectChangeEvent,
} from "@mui/material";
import url from "../../API";
import { makeStyles } from "@mui/styles";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { identity } from "lodash";
export default function InformationPage() {
  const classes = countryStyles();
  const navigate = useNavigate();
  const token = localStorage.getItem("tkn");
  const data = localStorage.getItem("data");
  const parsedData = JSON.parse(data);
  const [visaInformation, setVisaInformation] = React.useState([]);
  const [visaPhotos, setVisaPhotos] = React.useState([]);
  const [fee_details, setFeedetails] = React.useState([]);
  const [visaPrevPics, setVisaPics] = React.useState([]);
  const [visaId, setVisaId] = React.useState("");
  const [logoPhotos, setlogoPhotos] = React.useState([]);
  const [faqvisa, setfaqvisa] = React.useState([]);
  const [testimonial, setTestimonial] = React.useState([]);
  const [partnerWrk, setPartnerwrk] = React.useState([]);
  const location = useLocation();
  const { updateId } = location.state || {};
  const [feeList, setFeelist] = React.useState([]);
  const [countryInfo, setCountryinfo] = React.useState([]);
  const useStyles = makeStyles((theme) => ({
    select: {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "#787878",
        },
        "&:hover fieldset": {
          borderColor: "#565656",
        },
        "&.Mui-focused fieldset": {
          borderColor: "#333333",
        },
      },
    },
    option: {
      '&[aria-selected="true"]': {
        backgroundColor: "#787878 !important",
        color: "white",
      },
    },
  }));
  const class2 = useStyles();

  const menuProps = {
    classes: { paper: class2.select },
    MenuListProps: {
      classes: { root: class2.option },
    },
  };
  const [state, setState] = React.useState({
    name: "",
    visa_thumbnail: "",
    visa_pricing: "",
    receive_days: "",
    //benifits: "",
    is_comingsoon: "",
    is_visa_issued_on_arriving: "",
    is_visa_required: "",
    entry_type: [],
    entry_id: [],
    //  receive_time: "",
    validity_period: "",
    validity_description: "",
    from_country: "",
    from_country_id: "",
    to_country: "",
    to_country_id: "",
    visa_photos: [],
    meta_title: "",
    meta_keywords: "",
    meta_description: "",
    isUpdate: false,
    visaId: null,

    fee_info: [
      { fee_type: "", fee_amount: "", fee_type_id: "", fee_description: "" },
    ],
    flight_change_over_description: EditorState.createEmpty(),
    benifits: EditorState.createEmpty(),
    faq_info: [{ question: "", answer: EditorState.createEmpty(), id: "" }],

    customer_testimonial: [
      {
        id: "",
        name: "",
        image: "",
        description: EditorState.createEmpty(),
        date: "",
        location: "",
        title: "",
        rating: "",
      },
    ],
    docs: [
      {
        docs_icon: "",
        docs_title: "",
        docs_id: "",
      },
    ],
    immigration: [
      {
        position: "",
        image: "",
        name: "",
        type: "",
        id: "",
      },
    ],
    question_info: [
      {
        type_question: "",
        type_list: [
          "text input",
          "Number",
          "Upload",
          "Date Picker",
          "Toggle",
          "Text Area",
          "Drop Down",
          "Check box",
          "Radio",
        ],
        question_type: "",
        question_type_id: "",
        name: "",
        choice: [],
        required: "",
        id: null,
      },
    ],
    // question: "",
    // answer: EditorState.createEmpty(),
    logo: [],
  });

  // const convertTime = (time24) => {
  //   const [hoursStr, minutesStr] = time24.split(":");
  //   let hours = parseInt(hoursStr, 10);
  //   const minutes = parseInt(minutesStr, 10);
  //   let meridiem = "AM";

  //   if (hours >= 12) {
  //     meridiem = "PM";
  //     hours %= 12;
  //   }

  //   if (hours === 0) {
  //     hours = 12; // 12 AM
  //   }

  //   return `${hours}:${minutes < 10 ? "0" : ""}${minutes}${meridiem}`;
  // };
  // const convertTo24HourFormat = (timeStr) => {
  //   const [hours, mins] = timeStr.split(/[: ]/);
  //   let formattedHours = parseInt(hours, 10);
  //   const meridiem = mins.slice(-2);
  //   console.log(meridiem);
  //   var zeroVal = "0o0";
  //   const minutes = mins.slice(0, -2) == "NaN" ? zeroVal : mins.slice(0, -2);

  //   if (meridiem.toUpperCase() === "PM" && formattedHours !== 12) {
  //     formattedHours += 12;
  //   } else if (meridiem.toUpperCase() === "AM" && formattedHours === 12) {
  //     formattedHours = 0;
  //   }

  //   const formattedTime = `${formattedHours
  //     .toString()
  //     .padStart(2, "0")}:${minutes}`;
  //   // return timeStr.slice(0, -2);
  //   return formattedTime;
  // };
  // const handleTimeChange = (newTime) => {
  //   const formattedTime = newTime.toLocaleString("en-US", {
  //     hour: "numeric",
  //     minute: "2-digit",
  //     hour12: true,
  //   });
  //   setState({
  //     ...state,
  //     receive_time: convertTime(formattedTime),
  //   });
  // };
  // const handleChange = (event: SelectChangeEvent[typeof state.entry_type]) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setState({
  //     ...state,
  //     entry_type: typeof value === "string" ? value.split(",") : value,
  //   });
  // };
  const removelogoPhotos = (index, chk) => {
    if (chk == "prev") {
      const newImages = [...logoPhotos];
      newImages.splice(index, 1);
      localStorage.removeItem(`image${index + 1}`);
      setlogoPhotos(newImages);
    } else {
      const newImages = [...state.logo];
      newImages.splice(index, 1);
      localStorage.removeItem(`image${index + 1}`);
      setState({ ...state, logo: newImages });
    }
  };
  async function table() {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        url + "entrytype/getallentrytype",
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      setVisaInformation(actualData.Visa_Information);
    } catch (e) {
      console.log(e);
    }
  }
  async function table3() {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(url + "feetypes/getallfeetypes", requestOptions);
      const actualData = await res.json();
      console.log(actualData);
      setFeelist(actualData.Fee_Types);
      return actualData.Fee_Types;
    } catch (e) {
      console.log(e);
    }
  }
  async function table2() {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(url + "country/getallcountry", requestOptions);
      const actualData = await res.json();
      console.log(actualData);
      setCountryinfo(actualData.Country);
      return actualData.Country;
    } catch (e) {
      console.log(e);
    }
  }
  // async function table4() {
  //   try {
  //     const loginHeaders = new Headers();
  //     loginHeaders.append("Content-Type", "application/json");

  //     // Assuming you have an authorization token stored in localStorage
  //     const authToken = localStorage.getItem("tkn");
  //     if (authToken) {
  //       loginHeaders.append("Authorization", `Bearer ${authToken}`);
  //     }
  //     const updateobj = JSON.parse(localStorage.getItem("updateVisa"));
  //     const data = { visa_id: updateobj.id };
  //     const requestOptions = {
  //       method: "POST",
  //       headers: loginHeaders,
  //       body: JSON.stringify(data),
  //     };
  //     const res = await fetch(
  //       url + "visa/visafeedetailsbyvisaid",
  //       requestOptions
  //     );
  //     const actualData = await res.json();
  //     console.log(actualData);
  //     setFeedetails(actualData.Visa_Fee_details);
  //     return actualData.Visa_Fee_details;
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }
  // async function table5() {
  //   try {
  //     const loginHeaders = new Headers();
  //     loginHeaders.append("Content-Type", "application/json");

  //     // Assuming you have an authorization token stored in localStorage
  //     const authToken = localStorage.getItem("tkn");
  //     if (authToken) {
  //       loginHeaders.append("Authorization", `Bearer ${authToken}`);
  //     }
  //     const updateobj = JSON.parse(localStorage.getItem("updateVisa"));
  //     const data = { visa_id: updateobj.id };
  //     const requestOptions = {
  //       method: "POST",
  //       headers: loginHeaders,
  //       body: JSON.stringify(data),
  //     };
  //     const res = await fetch(url + "visa/faqdetailsbyvisaid", requestOptions);
  //     const actualData = await res.json();
  //     console.log(actualData);
  //     setfaqvisa(actualData.Question_Answer_details);
  //     return actualData.Question_Answer_details;
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }
  // async function table6() {
  //   try {
  //     const loginHeaders = new Headers();
  //     loginHeaders.append("Content-Type", "application/json");

  //     // Assuming you have an authorization token stored in localStorage
  //     const authToken = localStorage.getItem("tkn");
  //     if (authToken) {
  //       loginHeaders.append("Authorization", `Bearer ${authToken}`);
  //     }
  //     const updateobj = JSON.parse(localStorage.getItem("updateVisa"));
  //     const data = { visa_id: updateobj.id };
  //     const requestOptions = {
  //       method: "POST",
  //       headers: loginHeaders,
  //       body: JSON.stringify(data),
  //     };
  //     const res = await fetch(
  //       url + "visa/partnerweworkwithdetailsbyvisaid",
  //       requestOptions
  //     );
  //     const actualData = await res.json();
  //     console.log(actualData);
  //     setfaqvisa(actualData.Partners_Details);
  //     const pics = actualData.Partners_Details.map((item) => item.logo);
  //     setlogoPhotos(pics);
  //     return actualData.Partners_Details;
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }
  // async function table7() {
  //   try {
  //     const loginHeaders = new Headers();
  //     loginHeaders.append("Content-Type", "application/json");

  //     // Assuming you have an authorization token stored in localStorage
  //     const authToken = localStorage.getItem("tkn");
  //     if (authToken) {
  //       loginHeaders.append("Authorization", `Bearer ${authToken}`);
  //     }
  //     const updateobj = JSON.parse(localStorage.getItem("updateVisa"));
  //     const data = { visa_id: updateobj.id };
  //     const requestOptions = {
  //       method: "POST",
  //       headers: loginHeaders,
  //       body: JSON.stringify(data),
  //     };
  //     const res = await fetch(
  //       url + "visa/customertestimonialdetailsbyvisaid",
  //       requestOptions
  //     );
  //     const actualData = await res.json();
  //     console.log(actualData);
  //     setfaqvisa(actualData.Customer_Details);
  //     return actualData.Customer_Details;
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }
  const handleStateChange = (name, value) => {
    if (!!name) {
      setState({
        ...state,
        [name]: value,
      });
    }
  };
  const handleEntryTypeChange = (v) => {
    const selectedEntryTypeName = v;

    const selectedEntryType = visaInformation.find(
      (item) => item.entry_type_name === selectedEntryTypeName
    );

    setState((prevState) => ({
      ...prevState,
      entry_type: v,
      entry_id: selectedEntryType ? selectedEntryType.id : "",
    }));
    console.log(state);
  };
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const tableData = await table();
        const countryInfoData = await table2();
        const fee_typeData = await table3();
        if (updateId) {
          try {
            const loginHeaders = new Headers();
            loginHeaders.append("Content-Type", "application/json");

            // Assuming you have an authorization token stored in localStorage
            const authToken = localStorage.getItem("tkn");
            if (authToken) {
              loginHeaders.append("Authorization", `Bearer ${authToken}`);
            }
            const data = { visa_id: updateId };
            const requestOptions = {
              method: "POST",
              headers: loginHeaders,
              body: JSON.stringify(data),
            };
            const res = await fetch(
              url + "visa/visadetailsbyvisaid",
              requestOptions
            );
            const actualData = await res.json();
            const visaDetails = actualData.visa_details;

            setVisaPics(actualData.visa_photos_details);
            const questionTypes = [
              "text input",
              "Number",
              "Upload",
              "Date Picker",
              "Toggle",
              "Text Area",
              "Drop Down",
              "Check box",
              "Radio",
            ];

            const formattedQuestions = actualData.questions_with_answers.map(
              (questionWithAnswers) => {
                const { question, answers } = questionWithAnswers;
                const typeQuestion = [7, 8, 9].includes(question.question_type)
                  ? "multiple"
                  : "";

                const choices =
                  typeQuestion === "multiple"
                    ? answers.map((answer) => ({ choice_title: answer.choice }))
                    : [];

                return {
                  type_question: typeQuestion,
                  type_list: questionTypes,
                  question_type:
                    questionTypes[question.question_type - 1] || "",
                  question_type_id: question.question_type,
                  name: question.question_name,
                  choice: choices,
                  required: question.required_or_not,
                  id: question.id,
                };
              }
            );

            const pics = actualData.visa_photos_details.map(
              (item) => item.visa_photos
            );
            console.log(actualData.entry_type_details.entry_type_name);
            setVisaPhotos(pics);
            setState((prevState) => ({
              ...prevState,
              name: visaDetails.visa_name || "",
              visa_thumbnail: visaDetails.visa_thumbnail || "",
              visa_pricing: visaDetails.visa_pricing || "",
              receive_days: visaDetails.receive_days || "",
              is_comingsoon: visaDetails.is_comingsoon || false,
              is_visa_issued_on_arriving:
                visaDetails.is_visa_issued_on_arriving || false,
              is_visa_required: visaDetails.is_visa_required || false,
              entry_type: actualData.entry_type_details
                ? actualData.entry_type_details.map(
                    (entry) => entry.entry_type_name
                  )
                : [],
              entry_id: actualData.entry_type_details
                ? actualData.entry_type_details.map((entry) => entry.id)
                : [],
              validity_period: visaDetails.validity_period || "",
              validity_description: visaDetails.validity_description || "",
              from_country_id: visaDetails.from_country_id || "",
              from_country: actualData.from_country_name || "",
              to_country: actualData.to_country_name || "",
              to_country_id: visaDetails.to_country_id || "",
              meta_title: visaDetails.meta_title || "",
              meta_keywords: visaDetails.meta_keywords || "",
              meta_description: visaDetails.meta_description || "",
              fee_info: actualData.visa_fee_details.map((fee, idx) => ({
                fee_type: actualData.visa_type_details[idx].fee_name || "",
                fee_amount: fee.price || "",
                fee_type_id: fee.feetype_id || "",
                fee_description: fee.descriptions || "",
              })),
              flight_change_over_description:
                visaDetails.flight_change_over_descriptions
                  ? EditorState.createWithContent(
                      convertFromRaw(
                        JSON.parse(visaDetails.flight_change_over_descriptions)
                      )
                    )
                  : EditorState.createEmpty(),
              benifits: visaDetails.benifits
                ? EditorState.createWithContent(
                    convertFromRaw(JSON.parse(visaDetails.benifits))
                  )
                : EditorState.createEmpty(),
              faq_info: actualData.faq_for_visa_data.map((faq) => ({
                question: faq.question || "",
                answer: faq.answer
                  ? EditorState.createWithContent(
                      convertFromRaw(JSON.parse(faq.answer))
                    )
                  : EditorState.createEmpty(),
                id: faq.id || "",
              })),
              customer_testimonial: actualData.customer_testimonials.map(
                (testimonial) => ({
                  id: testimonial.id || "",
                  name: testimonial.customer_name || "",
                  image: testimonial.customer_image || "",
                  description: testimonial.description
                    ? EditorState.createWithContent(
                        convertFromRaw(JSON.parse(testimonial.description))
                      )
                    : EditorState.createEmpty(),
                  date: testimonial.date || "",
                  location: testimonial.customer_location || "",
                  title: testimonial.title || "",
                  rating: testimonial.rating || "",
                })
              ),
              docs: actualData.document_required_details.map((doc) => ({
                docs_icon: doc.document_icon || "",
                docs_title: doc.document_name || "",
                docs_id: doc.id || "",
              })),
              immigration: actualData.immigration_officer_data.map(
                (officer) => ({
                  position: officer.officer_position || "",
                  image: officer.officer_photo || "",
                  name: officer.officer_name || "",
                  type: officer.officer_type || "",
                  id: officer.id || "",
                })
              ),
              logo: actualData.partner_logos || [],
              visaId: visaDetails.id,
              isUpdate: true,
              question_info: formattedQuestions,
            }));
          } catch (e) {
            console.log(e);
          }
          //   const fee_detailsData = await table4();
          //   const faqData = await table5();
          //   const logodata = await table6();
          //   const customer_data = await table7();
          //   if (fee_detailsData && countryInfoData) {
          //     const id_type = localStorage.getItem("entry_id").split(",");
          //     const type_visa = localStorage.getItem("updateVisatype").split(",");
          //     const updateobj = JSON.parse(localStorage.getItem("updateVisa"));
          //     setVisaPics(updateobj.photos);
          //     const pics = updateobj.photos.map((item) => item.visa_photos);
          //     setVisaPhotos(pics);
          //     console.log(id_type);
          //     const to_info = countryInfoData.find(
          //       (item) => item.id === updateobj.to_country_id
          //     );
          //     const from_info = countryInfoData.find(
          //       (item) => item.id === updateobj.from_country_id
          //     );
          //     const new_fee =
          //       fee_detailsData.length !== 0
          //         ? fee_detailsData.map((item) => {
          //             return {
          //               fee_type: item.fee_type_details.fee_name,
          //               fee_amount: item.price,
          //               fee_type_id: item.feetype_id,
          //               fee_description: item.descriptions,
          //             };
          //           })
          //         : [
          //             {
          //               fee_type: "",
          //               fee_amount: "",
          //               fee_type_id: "",
          //               fee_description: "",
          //             },
          //           ];

          //     const new_faq =
          //       faqData.length !== 0
          //         ? faqData.map((item) => {
          //             return {
          //               question: item.Question,
          //               answer: EditorState.createWithContent(
          //                 convertFromRaw(JSON.parse(item.Answer))
          //               ),
          //               id: item.id,
          //             };
          //           })
          //         : [{ question: "", answer: EditorState.createEmpty(), id: "" }];
          //     const new_coustomer =
          //       customer_data.length !== 0
          //         ? customer_data.map((item) => {
          //             return {
          //               id: item.id,
          //               name: item.customer_name,
          //               image: item.customer_image,
          //               description: EditorState.createWithContent(
          //                 convertFromRaw(JSON.parse(item.description))
          //               ),
          //               date: item.date,
          //               location: item.customer_location,
          //               title: item.Title,
          //               rating: item.rating,
          //             };
          //           })
          //         : [
          //             {
          //               id: "",
          //               name: "",
          //               image: "",
          //               description: EditorState.createEmpty(),
          //               date: "",
          //               location: "",
          //               title: "",
          //               rating: "",
          //             },
          //           ];
          //     // setState({ ...state, fee_info: new_fee });
          //     // console.log(new_fee);

          //     setState({
          //       fee_info: new_fee,
          //       faq_info: new_faq,
          //       to_country: to_info ? to_info.country_name : "",
          //       to_country_id: updateobj.to_country_id,
          //       from_country: from_info ? from_info.country_name : "",
          //       from_country_id: updateobj.from_country_id,
          //       entry_id: id_type,
          //       entry_type: type_visa,
          //       name: updateobj.visa_name,
          //       visa_thumbnail: updateobj.visa_thumbnail,
          //       validity_description: updateobj.validity_description,
          //       visa_pricing: updateobj.visa_pricing,
          //       receive_days: updateobj.receive_days,
          //       //benifits: updateobj.benifits,
          //       is_comingsoon: updateobj.is_comingsoon == true ? "Yes" : "No",
          //       is_visa_issued_on_arriving:
          //         updateobj.is_visa_issued_on_arriving == true ? "Yes" : "No",
          //       is_visa_required:
          //         updateobj.is_visa_required == true ? "Yes" : "No",
          //       flight_change_over_description: EditorState.createWithContent(
          //         convertFromRaw(
          //           JSON.parse(updateobj.flight_change_over_descriptions)
          //         )
          //       ),
          //       benifits:
          //         //EditorState.createEmpty(),
          //         EditorState.createWithContent(
          //           convertFromRaw(JSON.parse(updateobj.benifits))
          //         ),
          //       isUpdate: true,
          //       visaId: updateobj.id,
          //       meta_title: updateobj.meta_title,
          //       meta_description: updateobj.meta_description,
          //       meta_keywords: updateobj.meta_keywords,
          //       validity_period: updateobj.validity_period,
          //       customer_testimonial: new_coustomer,
          //     });
          //   }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle errors if necessary
      }
    };
    fetchData();
  }, []);
  React.useEffect(() => {}, [
    countryInfo,
    feeList,
    visaInformation,
    fee_details,
  ]);
  const removePhotos = (index, chk, imgstr) => {
    if (chk == "prev") {
      const newImages = [...visaPhotos];
      newImages.splice(index, 1);
      var matchingObject = visaPrevPics.find(
        (obj) => obj.visa_photos === imgstr
      );
      axios
        .post(
          url + "visaphotos/deletevisaphotos",
          {
            id: matchingObject.id,
            //description pass the text only
            //remove the thumbnail image
            //json pasrse string to send description
            // visa_time: state.visa_time,
          },
          {
            headers: {
              /* Your headers here */
              "Content-Type": "application/json", // Example header
              Authorization: `Bearer ${token}`, // Example authorization header
            },
          }
        )
        .then((r) => {
          matchingObject = {};
          console.log(r);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          // Handle error here
        });
      localStorage.removeItem(`image${index + 1}`);
      setVisaPhotos(newImages);
    } else {
      const newImages = [...state.visa_photos];
      newImages.splice(index, 1);
      localStorage.removeItem(`image${index + 1}`);

      setState({ ...state, visa_photos: newImages });
    }
  };
  const handleUpdateInfo = async (id) => {
    var visapics = visaPhotos.concat(state.visa_photos);
    visapics = visapics.filter((item) => item !== null && item !== undefined);
    console.log(visapics);

    const requiredFields = [
      state.visa_thumbnail,
      state.visa_pricing,
      state.receive_days,
      state.is_comingsoon,
      state.is_visa_issued_on_arriving,
      state.is_visa_required,
      state.flight_change_over_description.getCurrentContent().hasText(),
      state.benifits.getCurrentContent().hasText(),
      state.from_country_id,
      state.to_country_id,
      state.validity_period,
      state.validity_description,
      state.meta_title,
      state.meta_keywords,
      state.meta_description,
      state.name,
    ];

    if (requiredFields.some((field) => field === "" || field === false)) {
      toast.error("Provide all basic information", {
        position: "top-center",
      });
      return;
    }

    try {
      const visaInfoResponse = await axios.post(
        url + "visainformation/updatevisainformation",
        {
          visa_name: state.name,
          visa_thumbnail: state.visa_thumbnail,
          visa_pricing: state.visa_pricing,
          receive_days: state.receive_days,
          is_comingsoon: state.is_comingsoon,
          is_visa_issued_on_arriving: state.is_visa_issued_on_arriving,
          is_visa_required: state.is_visa_required,
          flight_change_over_descriptions: JSON.stringify(
            convertToRaw(
              state.flight_change_over_description.getCurrentContent()
            )
          ),
          benifits: JSON.stringify(
            convertToRaw(state.benifits.getCurrentContent())
          ),
          id: id,
          validity_period: state.validity_period,
          validity_description: state.validity_description,
          visa_photos: visapics,
          from_country_id: state.from_country_id,
          to_country: state.to_country_id,
          meta_title: state.meta_title,
          meta_keywords: state.meta_keywords,
          meta_description: state.meta_description,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (visaInfoResponse.status === 200) {
        for (let i = 0; i < state.fee_info.length; i++) {
          const fee = state.fee_info[i];
          if (
            fee.fee_amount === "" ||
            fee.fee_type_id === "" ||
            fee.fee_description === ""
          ) {
            toast.error("Please provide the fee information", {
              position: "top-center",
            });
            return;
          } else {
            await axios.post(
              url + "visafees/updatevisafees",
              {
                visa_id: id,
                feetype_id: fee.fee_type_id,
                price: fee.fee_amount,
                descriptions: fee.fee_description,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            );
          }
        }

        for (let i = 0; i < state.faq_info.length; i++) {
          const faq = state.faq_info[i];
          if (
            faq.question === "" ||
            !faq.answer || // Check if answer is defined
            !faq.answer.getCurrentContent || // Check if it has the method
            !faq.answer.getCurrentContent().hasText()
          ) {
            toast.error("Please provide the faq information", {
              position: "top-center",
            });
            return;
          } else if (faq.id === "") {
            await axios.post(
              url + "faqforvisa/createfaqforvisa",
              {
                question: faq.question,
                answer: JSON.stringify(
                  convertToRaw(faq.answer.getCurrentContent())
                ),
                visa_id: id,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            );
          } else {
            await axios.post(
              url + "faqforvisa/updatefaqforvisa",
              {
                visa_id: id,
                question: faq.question,
                answer: JSON.stringify(
                  convertToRaw(faq.answer.getCurrentContent())
                ),
                id: faq.id,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            );
          }
        }

        for (let i = 0; i < state.customer_testimonial.length; i++) {
          const test = state.customer_testimonial[i];
          if (
            test.name === "" ||
            test.date === "" ||
            test.image === "" ||
            !test.description || // Check if description is defined
            !test.description.getCurrentContent || // Check if it has the method
            !test.description.getCurrentContent().hasText() ||
            test.location === "" ||
            test.title === "" ||
            test.rating === ""
          ) {
            toast.error("Please provide the customer testimonial information", {
              position: "top-center",
            });
            return;
          } else if (test.id === "") {
            await axios.post(
              url + "customertestimonial/createcustomertestimonial",
              {
                customer_name: test.name,
                customer_image: test.image,
                description: JSON.stringify(
                  convertToRaw(test.description.getCurrentContent())
                ),
                customer_location: test.location,
                title: test.title,
                date: test.date,
                visa_id: id,
                rating: test.rating,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            );
          } else {
            await axios.post(
              url + "customertestimonial/updatecustomertestimonial",
              {
                customer_name: test.name,
                customer_image: test.image,
                description: JSON.stringify(
                  convertToRaw(test.description.getCurrentContent())
                ),
                customer_location: test.location,
                title: test.title,
                date: test.date,
                rating: test.rating,
                id: test.id,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            );
          }
        }

        for (let i = 0; i < state.docs.length; i++) {
          const doc = state.docs[i];
          if (doc.docs_title === "" || doc.docs_icon === "") {
            toast.error("Please provide the required document information", {
              position: "top-center",
            });
            return;
          } else if (doc.docs_id === "") {
            await axios.post(
              url + "documentrequired/createdocumentrequired",
              {
                visa_id: id,
                document_icon: doc.docs_icon,
                document_name: doc.docs_title,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            );
          } else {
            await axios.post(
              url + "documentrequired/updatedocumentrequired",
              {
                visa_id: id,
                id: doc.docs_id,
                document_icon: doc.docs_icon,
                document_name: doc.docs_title,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            );
          }
        }

        for (let i = 0; i < state.immigration.length; i++) {
          const officer = state.immigration[i];
          if (
            officer.position === "" ||
            officer.image === "" ||
            officer.name === "" ||
            officer.type === ""
          ) {
            toast.error(
              "Please provide the required immigration officer information",
              {
                position: "top-center",
              }
            );
            return;
          } else if (officer.id === "") {
            const officerResponse = await axios.post(
              url + "immigrationofficerlist/createimmigrationofficerlist",
              {
                officer_name: officer.name,
                officer_photo: officer.image,
                officer_position: officer.position,
                officer_type: officer.type,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            );

            await axios.post(
              url + "visaofficermap/createvisaofficermap",
              {
                visa_id: id,
                officer_id: officerResponse.data.new_immigration_officer_id,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            );
          } else {
            await axios.post(
              url + "immigrationofficerlist/updateimmigrationofficerlist",
              {
                officer_name: officer.name,
                officer_photo: officer.image,
                officer_position: officer.position,
                officer_type: officer.type,
                id: officer.id,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            );
          }
        }
        for (let i = 0; i < state.question_info.length; i++) {
          const question = state.question_info[i];

          if (question.question_type_id === "" || question.name === "") {
            toast.error("Please provide the required document information", {
              position: "top-center",
            });
            return;
          } else if (question.id == null) {
            if ([7, 8, 9].includes(question.question_type_id)) {
              const data = question.choice.map((item) => item.choice_title);
              axios
                .post(
                  url + "question/createquestion",
                  {
                    visa_id: updateId,
                    question_name: question.name,
                    question_type: question.question_type_id,
                    choice: data,
                    required_or_not: question.required,
                    //  description: JSON.stringify(question.description), // Assuming you have a description field
                  },
                  {
                    headers: {
                      "Content-Type": "application/json", // Example header
                      Authorization: `Bearer ${token}`, // Example authorization header
                    },
                  }
                )
                .then((r) => {
                  console.log(r);
                })
                .catch((error) => {
                  console.error("Error fetching data:", error);
                  // Handle error here
                });
            } else {
              axios
                .post(
                  url + "question/createquestion",
                  {
                    visa_id: updateId,
                    question_name: question.name,
                    question_type: question.question_type_id,
                    required_or_not: question.required,
                    //  description: JSON.stringify(question.description), // Assuming you have a description field
                  },
                  {
                    headers: {
                      "Content-Type": "application/json", // Example header
                      Authorization: `Bearer ${token}`, // Example authorization header
                    },
                  }
                )
                .then((r) => {
                  console.log(r);
                })
                .catch((error) => {
                  console.error("Error fetching data:", error);
                  // Handle error here
                });
            }
          } else {
            if ([7, 8, 9].includes(question.question_type_id)) {
              const data = question.choice.map((item) => item.choice_title);
              axios
                .post(
                  url + "question/updatequestion",
                  {
                    id: question.id,
                    visa_id: updateId,
                    question_name: question.name,
                    question_type: question.question_type_id,
                    choice: data,
                    required_or_not: question.required,
                    // description: JSON.stringify(question.description), // Assuming you have a description field
                  },
                  {
                    headers: {
                      "Content-Type": "application/json", // Example header
                      Authorization: `Bearer ${token}`, // Example authorization header
                    },
                  }
                )
                .then((r) => {
                  console.log(r);
                  // Navigate only after the last request is done (optional)
                })
                .catch((error) => {
                  console.error("Error fetching data:", error);
                  // Handle error here
                });
            } else {
              axios
                .post(
                  url + "question/updatequestion",
                  {
                    id: question.id,
                    visa_id: updateId,
                    question_name: question.name,
                    question_type: question.question_type_id,
                    choice: [],
                    required_or_not: question.required,
                    // description: JSON.stringify(question.description), // Assuming you have a description field
                  },
                  {
                    headers: {
                      "Content-Type": "application/json", // Example header
                      Authorization: `Bearer ${token}`, // Example authorization header
                    },
                  }
                )
                .then((r) => {
                  console.log(r);
                  // Navigate only after the last request is done (optional)
                })
                .catch((error) => {
                  console.error("Error fetching data:", error);
                  // Handle error here
                });
            }
          }
        }

        await axios.post(
          url + "partnerweworkwith/updatepartnerweworkwith",
          {
            visa_id: id,
            logo: visapics,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        await axios.post(
          url + "visaentrytypemap/updatevisaentrytypemap",
          {
            visa_id: id,
            // id: localStorage.getItem("entrymapId"),
            entry_type_id: state.entry_id,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        toast.success("Visa Information updated successfully", {
          position: "top-center",
        });

        setState({
          ...state,
          name: "",
          visa_thumbnail: "",
          visa_pricing: "",
          receive_days: "",
          is_comingsoon: false,
          is_visa_issued_on_arriving: false,
          is_visa_required: false,
          flight_change_over_description: EditorState.createEmpty(),
          benifits: EditorState.createEmpty(),
          from_country_id: "",
          to_country_id: "",
          validity_period: "",
          validity_description: "",
          visa_photos: [],
          meta_title: "",
          meta_keywords: "",
          meta_description: "",
          fee_info: [],
          faq_info: [],
          customer_testimonial: [],
          docs: [],
          immigration: [],
          logo: [],
        });
      }
    } catch (error) {
      console.error("Error updating visa information: ", error);
      toast.error("Error updating visa information", {
        position: "top-center",
      });
    }
  };

  const handleCreateInfo = async () => {
    const requiredFields = [
      state.visa_thumbnail,
      state.visa_pricing,
      state.receive_days,
      state.is_comingsoon,
      state.is_visa_issued_on_arriving,
      state.is_visa_required,
      state.flight_change_over_description.getCurrentContent().hasText(),
      state.benifits.getCurrentContent().hasText(),
      state.from_country_id,
      state.to_country_id,
      state.validity_period,
      state.validity_description,
      state.meta_title,
      state.visa_photos,
      state.meta_keywords,
      state.meta_description,
      state.name,
    ];

    if (requiredFields.some((field) => field === "" || field === false)) {
      toast.error("Please provide the basic information", {
        position: "top-center",
      });
      return;
    }

    try {
      const visaInfoResponse = await axios.post(
        url + "visainformation/createvisainformation",
        {
          visa_name: state.name,
          visa_thumbnail: state.visa_thumbnail,
          visa_pricing: state.visa_pricing,
          receive_days: state.receive_days,
          is_comingsoon: state.is_comingsoon,
          is_visa_issued_on_arriving: state.is_visa_issued_on_arriving,
          is_visa_required: state.is_visa_required,
          flight_change_over_descriptions: JSON.stringify(
            convertToRaw(
              state.flight_change_over_description.getCurrentContent()
            )
          ),
          benifits: JSON.stringify(
            convertToRaw(state.benifits.getCurrentContent())
          ),
          validity_period: state.validity_period,
          validity_description: state.validity_description,
          visa_photos: state.visa_photos,
          from_country_id: state.from_country_id,
          to_country_id: state.to_country_id,
          meta_title: state.meta_title,
          meta_keywords: state.meta_keywords,
          meta_description: state.meta_description,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const visaId = visaInfoResponse.data.visa_id;
      setVisaId(visaId);
      localStorage.removeItem("thumbnail");

      for (let i = 0; i < state.fee_info.length; i++) {
        const fee = state.fee_info[i];
        if (
          fee.fee_amount === "" ||
          fee.fee_type_id === "" ||
          fee.fee_description === ""
        ) {
          toast.error("Please provide the fee information", {
            position: "top-center",
          });
          return;
        }

        await axios.post(
          url + "visafees/createvisafees",
          {
            visa_id: visaId,
            feetype_id: fee.fee_type_id,
            price: fee.fee_amount,
            descriptions: fee.fee_description,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }

      for (let i = 0; i < state.faq_info.length; i++) {
        const faq = state.faq_info[i];
        if (faq.question === "" || !faq.answer.getCurrentContent().hasText()) {
          toast.error("Please provide the FAQ information", {
            position: "top-center",
          });
          return;
        }

        await axios.post(
          url + "faqforvisa/createfaqforvisa",
          {
            visa_id: visaId,
            question: faq.question,
            answer: JSON.stringify(
              convertToRaw(faq.answer.getCurrentContent())
            ),
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }

      for (let i = 0; i < state.customer_testimonial.length; i++) {
        const test = state.customer_testimonial[i];
        if (
          test.name === "" ||
          test.date === "" ||
          test.image === "" ||
          !test.description.getCurrentContent().hasText() ||
          test.location === "" ||
          test.title === "" ||
          test.rating === ""
        ) {
          toast.error("Please provide the customer testimonial information", {
            position: "top-center",
          });
          return;
        }

        await axios.post(
          url + "customertestimonial/createcustomertestimonial",
          {
            customer_name: test.name,
            customer_image: test.image,
            description: JSON.stringify(
              convertToRaw(test.description.getCurrentContent())
            ),
            customer_location: test.location,
            Title: test.title,
            date: test.date,
            visa_id: visaId,
            rating: test.rating,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }

      for (let i = 0; i < state.docs.length; i++) {
        const doc = state.docs[i];
        if (doc.docs_title === "" || doc.docs_icon === "") {
          toast.error("Please provide the required document information", {
            position: "top-center",
          });
          return;
        }

        await axios.post(
          url + "documentrequired/createdocumentrequired",
          {
            visa_id: visaId,
            document_icon: doc.docs_icon,
            document_name: doc.docs_title,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }

      for (let i = 0; i < state.immigration.length; i++) {
        const officer = state.immigration[i];
        if (
          officer.position === "" ||
          officer.image === "" ||
          officer.name === "" ||
          officer.type === ""
        ) {
          toast.error(
            "Please provide the required immigration officer information",
            {
              position: "top-center",
            }
          );
          return;
        }

        const officerResponse = await axios.post(
          url + "immigrationofficerlist/createimmigrationofficerlist",
          {
            officer_name: officer.name,
            officer_photo: officer.image,
            officer_position: officer.position,
            officer_type: officer.type,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        await axios.post(
          url + "visaofficermap/createvisaofficermap",
          {
            visa_id: visaId,
            officer_id: officerResponse.data.new_immigration_officer_id,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }

      for (let i = 0; i < state.question_info.length; i++) {
        const question = state.question_info[i];
        if (
          question.name === "" ||
          question.question_type_id === "" ||
          question.required === ""
        ) {
          toast.error("Please provide the fee information", {
            position: "top-center",
          });
          return;
        }

        if ([7, 8, 9].includes(question.question_type_id)) {
          const data = question.choice.map((item) => item.choice_title);
          axios
            .post(
              url + "question/createquestion",
              {
                visa_id: visaId,
                question_name: question.name,
                question_type: question.question_type_id,
                choice: data,
                required_or_not: question.required,
                //  description: JSON.stringify(question.description), // Assuming you have a description field
              },
              {
                headers: {
                  "Content-Type": "application/json", // Example header
                  Authorization: `Bearer ${token}`, // Example authorization header
                },
              }
            )
            .then((r) => {
              console.log(r);
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
              // Handle error here
            });
        } else {
          axios
            .post(
              url + "question/createquestion",
              {
                visa_id: visaId,
                question_name: question.name,
                question_type: question.question_type_id,
                required_or_not: question.required,
                //  description: JSON.stringify(question.description), // Assuming you have a description field
              },
              {
                headers: {
                  "Content-Type": "application/json", // Example header
                  Authorization: `Bearer ${token}`, // Example authorization header
                },
              }
            )
            .then((r) => {
              console.log(r);
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
              // Handle error here
            });
        }
      }

      await axios.post(
        url + "partnerweworkwith/createpartnerweworkwith",
        {
          visa_id: visaId,
          logo: state.logo,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      await axios.post(
        url + "visaentrytypemap/createvisaentrytypemap",
        {
          visa_id: visaId,
          entry_type_id: state.entry_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setState({
        name: "",
        visa_thumbnail: "",
        visa_pricing: "",
        receive_days: "",
        is_comingsoon: false,
        is_visa_issued_on_arriving: false,
        is_visa_required: false,
        flight_change_over_description: EditorState.createEmpty(),
        benifits: EditorState.createEmpty(),
        from_country_id: "",
        to_country_id: "",
        validity_period: "",
        validity_description: "",
        visa_photos: [],
        meta_title: "",
        meta_keywords: "",
        meta_description: "",
        fee_info: [],
        faq_info: [],
        customer_testimonial: [],
        docs: [],
        immigration: [],
        logo: [],
      });

      navigate("/District_Authority_List");
      toast.success("Visa Information created successfully", {
        position: "top-center",
      });
    } catch (error) {
      console.error("Error creating visa information: ", error);
      toast.error("An error occurred. Please try again.", {
        position: "top-center",
      });
    }
  };

  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            {" "}
            <Typography variant="h3" color={"primary"}>
              Visa Information Form
            </Typography>
          </div>

          <div
            onClick={() => {
              localStorage.clear();
              navigate("/");
            }}
            style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
          >
            <Typography
              variant="h4"
              color="primary"
              style={{ display: "flex", alignItems: "center" }}
            >
              Log out
              <MdLogout size={30} style={{ marginLeft: "8px" }} />
            </Typography>
          </div>
        </div>
        <Divider className={classes.divider} />
        <Grid container spacing={4} className={classes.contentMain}>
          <Grid item xs={12} md={3}>
            <CustomInputField
              label="Visa Name"
              fullWidth
              value={state.name}
              name="name"
              onChange={(e) => handleStateChange("name", e.target.value)}
            />
          </Grid>
          <Grid item xs={2}>
            <CustomInputAndSelectField
              options={countryInfo.map((item) => item.country_name)} // Set options from entry type names
              value={state.from_country}
              label="From Country"
              fullWidth
              changeCallBack={(e, v) => {
                console.log(v);
                const selectedEntryType = countryInfo.find(
                  (item) => item.country_name === v
                );

                setState((prevState) => ({
                  ...prevState,
                  from_country: v,
                  from_country_id: selectedEntryType
                    ? selectedEntryType.id
                    : "",
                }));
              }}
              // Handle onChange event
            />
          </Grid>
          <Grid item xs={2}>
            <CustomInputAndSelectField
              options={countryInfo.map((item) => item.country_name)} // Set options from entry type names
              value={state.to_country}
              label="To Country"
              fullWidth
              changeCallBack={(e, v) => {
                console.log(v);
                const selectedEntryType = countryInfo.find(
                  (item) => item.country_name === v
                );

                setState((prevState) => ({
                  ...prevState,
                  to_country: v,
                  to_country_id: selectedEntryType ? selectedEntryType.id : "",
                }));
              }}
              // Handle onChange event
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <CustomInputField
              type="file"
              fullWidth
              label="Visa Thumbnail"
              onChange={(e) => {
                const imageRef = ref(storage, `/thumbnail+${Math.random()}`);
                uploadBytes(imageRef, e.target.files[0]).then(() => {
                  getDownloadURL(imageRef)
                    .then((url) => {
                      localStorage.setItem("thumbnail", url);
                      setState({
                        ...state,
                        visa_thumbnail: localStorage.getItem("thumbnail"),
                      });
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            {state.visa_thumbnail && (
              <>
                <p>Thumbnail image</p>
                <Avatar
                  variant="rounded"
                  alt="VISA"
                  src={state.visa_thumbnail}
                  style={{
                    margin: "auto",
                  }}
                />
              </>
            )}
          </Grid>
          <Grid item xs={12} md={7}>
            <p>Visa Photos</p>
            <input
              style={{
                padding: "10px",
                borderColor: "rgba(148,158,171,0.32)",
                boxShadow: "1px 3px 10px rgba(0,0,0,0.17)",
                width: "100%",
                borderRadius: "12px",
              }}
              type="file"
              multiple
              fullWidth
              label="Visa Photos"
              onChange={(e) => {
                const files = Array.from(e.target.files);
                console.log("Files array:", files); // Check if files are converted to an array
                files.map((el, index) => {
                  var imageRef = ref(storage, `/images+${Math.random()}`);
                  uploadBytes(imageRef, el).then(() => {
                    getDownloadURL(imageRef)
                      .then((url) => {
                        //console.log("Image URL:", url);
                        localStorage.setItem(`image${index + 1}`, url);

                        set_img();
                      })
                      .catch((error) => {
                        //console.log(error);
                      });
                  });
                  function set_img() {
                    const data = [];
                    for (let i = 1; i <= files.length; i++) {
                      if (localStorage.getItem(`image${i}`)) {
                        data.push(localStorage.getItem(`image${i}`));
                      }
                    }
                    console.log(data);
                    console.log(state.visa_photos);
                    setState({
                      ...state,
                      visa_photos: data,
                    });
                  }
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={7}></Grid>
          <Grid item xs={12} style={{ display: "flex", gap: "20px" }}>
            {visaPhotos != null
              ? visaPhotos.map((el, idx) => (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <img src={el} style={{ height: "50px" }} />
                      <CustomButton
                        variant="contained"
                        color="error"
                        onClick={() => removePhotos(idx, "prev", el)}
                        icon={<FaTimes />}
                      />
                    </div>
                  </>
                ))
              : console.log(visaPhotos)}

            {state.visa_photos != null
              ? state.visa_photos.map((el, idx) => (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <img src={el} style={{ height: "50px" }} />
                      <CustomButton
                        variant="contained"
                        color="error"
                        onClick={() => removePhotos(idx, "new", el)}
                        icon={<FaTimes />}
                      />
                    </div>
                  </>
                ))
              : console.log(state.visa_photos)}
          </Grid>
          <Grid item xs={12} md={3}>
            <CustomInputField
              label="Visa Pricing (Amount)"
              fullWidth
              value={state.visa_pricing}
              onChange={(e) =>
                handleStateChange("visa_pricing", e.target.value)
              }
            />
          </Grid>
          {/* <Grid item xs={7}>
            <CustomInputAndSelectField
              options={countryInfo.map((item) => item.country_name)} // Set options from entry type names
              value={state.from_country}
              label="From Country"
              fullWidth
              changeCallBack={(e, v) => {
                console.log(v);
                const selectedEntryType = countryInfo.find(
                  (item) => item.country_name === v
                );

                setState((prevState) => ({
                  ...prevState,
                  from_country: v,
                  from_country_id: selectedEntryType
                    ? selectedEntryType.id
                    : "",
                }));
              }}
              // Handle onChange event
            />
          </Grid> */}
          {/* <Grid item xs={7}>
            <CustomInputAndSelectField
              options={countryInfo.map((item) => item.country_name)} // Set options from entry type names
              value={state.to_country}
              label="To Country"
              fullWidth
              changeCallBack={(e, v) => {
                console.log(v);
                const selectedEntryType = countryInfo.find(
                  (item) => item.country_name === v
                );

                setState((prevState) => ({
                  ...prevState,
                  to_country: v,
                  to_country_id: selectedEntryType ? selectedEntryType.id : "",
                }));
              }}
              // Handle onChange event
            />
          </Grid> */}
          <Grid item xs={12} md={4}>
            <CustomInputField
              type="number"
              label="Receive days"
              fullWidth
              value={state.receive_days}
              onChange={(e) =>
                handleStateChange("receive_days", e.target.value)
              }
            />
          </Grid>
          <Grid item xs={12}>
            Flight Description
            <p />
            <Editor
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              editorState={state.flight_change_over_description}
              onEditorStateChange={(e) =>
                setState({
                  ...state,
                  flight_change_over_description: e,
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            Benefits
            <p />
            <Editor
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              editorState={state.benifits}
              onEditorStateChange={(e) =>
                setState({
                  ...state,
                  benifits: e,
                })
              }
            />
          </Grid>
          {/* <Grid item xs={12} md={7}>
            <CustomInputField
              label="Benefits"
              fullWidth
              value={state.benifits}
              onChange={(e) => handleStateChange("benifits", e.target.value)}
            />
          </Grid> */}
          {/* <Grid item xs={6}>
            <p>RECEIVE TIME</p>
            <TimePicker
              onChange={handleTimeChange}
              value={state.receive_time}
              format="h:mm a" //for 12-hour format with AM/PM
              style={{ width: "0px" }}
            />
          </Grid> */}
          <Grid item xs={7}>
            <CustomInputField
              label="Validity period"
              fullWidth
              value={state.validity_period}
              onChange={(e) =>
                handleStateChange("validity_period", e.target.value)
              }
            />
          </Grid>{" "}
          <Grid item xs={7}>
            <CustomInputField
              label="Validity description"
              fullWidth
              value={state.validity_description}
              onChange={(e) =>
                handleStateChange("validity_description", e.target.value)
              }
            />
          </Grid>{" "}
          <Grid item xs={5}></Grid>
          <Grid item xs={12} md={4}>
            <CustomInputAndSelectField
              options={["Yes", "No"]}
              value={state.is_comingsoon}
              changeCallBack={(e, v) => {
                console.log(v);
                if (v == "Yes") {
                  handleStateChange("is_comingsoon", "True");
                } else {
                  handleStateChange("is_comingsoon", "False");
                }
              }}
              label="Is available soon"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <CustomInputAndSelectField
              options={["Yes", "No"]}
              label="Visa Required"
              value={state.is_visa_required}
              changeCallBack={(e, v) => {
                console.log(v);
                if (v == "Yes") {
                  handleStateChange("is_visa_required", "True");
                } else {
                  handleStateChange("is_visa_required", "False");
                }
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={5}></Grid>
          <Grid item xs={12} md={4}>
            <CustomInputAndSelectField
              options={["Yes", "No"]}
              value={state.is_visa_issued_on_arriving}
              changeCallBack={(e, v) => {
                console.log(v);
                if (v == "Yes") {
                  handleStateChange("is_visa_issued_on_arriving", "True");
                } else {
                  handleStateChange("is_visa_issued_on_arriving", "False");
                }
              }}
              label="visa issued on arriving"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <InputLabel id="demo-simple-select-label">Entry Type</InputLabel>
            <Select
              style={{
                padding: "10px",
                borderColor: "rgba(148,158,171,0.32)",
                boxShadow: "1px 3px 10px rgba(0,0,0,0.17)",
                width: "100%",
                borderRadius: "12px",
              }}
              labelId="demo-simple-select-label"
              multiple
              id="demo-simple-select"
              value={state.entry_type}
              label="Entry Type"
              // onChange={handleChange}
              input={<OutlinedInput label="Name" />}
              fullWidth
              onChange={(event) => {
                const { value } = event.target;
                console.log(value);
                const selectedIds = value
                  .map((selectedValue) => {
                    const selectedItem = visaInformation.find(
                      (item) => item.entry_type_name === selectedValue
                    );
                    return selectedItem ? selectedItem.id : null;
                  })
                  .filter(Boolean); // Filter out any null values
                console.log(selectedIds);
                setState({
                  ...state,
                  entry_type: value,
                  entry_id: selectedIds,
                });
              }}
            >
              {visaInformation.map((item) => (
                <MenuItem
                  key={item.id}
                  value={item.entry_type_name}
                  classes={{ root: class2.option }}
                >
                  {item.entry_type_name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          {/* <CustomInputAndSelectField
            //options={visaInformation.map((item) => item.entry_type_name)} // Set options from entry type names
            value={state.entry_type}
            label="Entry type"
            fullWidth
            changeCallBack={(e, v) => {
              console.log(v);
              handleEntryTypeChange(v);
            }}
            // Handle onChange event
          /> */}
          <Grid item xs={5}></Grid>
          <Grid item xs={12} md={4}>
            <CustomInputField
              label="Meta Title"
              fullWidth
              value={state.meta_title}
              name="name"
              onChange={(e) => handleStateChange("meta_title", e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <CustomInputField
              label="Meta Keywords"
              fullWidth
              value={state.meta_keywords}
              name="name"
              onChange={(e) =>
                handleStateChange("meta_keywords", e.target.value)
              }
            />
          </Grid>
          <Grid item xs={5}></Grid>
          <Grid item xs={7}>
            <CustomInputField
              label="Meta description"
              fullWidth
              value={state.meta_description}
              name="name"
              onChange={(e) =>
                handleStateChange("meta_description", e.target.value)
              }
            />
          </Grid>
          <div className={classes.contentMain} style={{ width: "100%" }}>
            <Paper className={classes.paper} style={{ marginLeft: "16px" }}>
              <Grid
                container
                spacing={3}
                className="content"
                alignItems={"end"}
              >
                {
                  <>
                    {state.fee_info ? (
                      state.fee_info.map((ch, idx) => (
                        <>
                          <Grid item xs={7}>
                            <CustomInputAndSelectField
                              options={feeList.map((item) => item.fee_name)} // Set options from entry type names
                              value={ch.fee_type}
                              label="Fee Types"
                              fullWidth
                              changeCallBack={(e, v) => {
                                const selectedEntryType = feeList.find(
                                  (item) => item.fee_name === v
                                );
                                const updatedvar = [...state.fee_info];
                                updatedvar[idx].fee_type = v;
                                updatedvar[idx].fee_type_id = selectedEntryType
                                  ? selectedEntryType.id
                                  : "";
                                setState({ ...state, fee_info: updatedvar });
                              }}
                              // Handle onChange event
                            />
                          </Grid>
                          <Grid item xs={7}>
                            <CustomInputField
                              label="Fee amount"
                              fullWidth
                              value={ch.fee_amount}
                              name="name"
                              onChange={(e) => {
                                const updatedvar = [...state.fee_info];
                                updatedvar[idx].fee_amount = e.target.value;
                                setState({ ...state, fee_info: updatedvar });
                              }}
                            />
                          </Grid>
                          <Grid item xs={7}>
                            <CustomInputField
                              label="Fee description"
                              fullWidth
                              value={ch.fee_description}
                              name="name"
                              onChange={(e) => {
                                const updatedvar = [...state.fee_info];
                                updatedvar[idx].fee_description =
                                  e.target.value;
                                setState({ ...state, fee_info: updatedvar });
                              }}
                            />
                          </Grid>
                          <Grid
                            style={{ paddingTop: "64px", paddingLeft: "20px" }}
                          >
                            {" "}
                            <span className={classes.actionBtn}>
                              <CustomButton
                                variant="contained"
                                color="error"
                                onClick={(e) => {
                                  const newArray = [...state.fee_info];
                                  newArray.splice(idx, 1);
                                  setState({ ...state, fee_info: newArray });
                                }}
                                icon={<FaTimes />}
                              />
                            </span>
                          </Grid>
                        </>
                      ))
                    ) : (
                      <p></p>
                    )}

                    <Grid item xs={12} md={4}>
                      <Button
                        style={{ marginTop: "18px" }}
                        variant="contained"
                        fullWidth
                        onClick={() => {
                          setState({
                            ...state,
                            fee_info: [
                              ...state.fee_info,
                              {
                                fee_type: "",
                                fee_amount: "",
                                fee_description: "",
                                fee_type_id: "",
                              },
                            ],
                          });
                        }}
                      >
                        Add Fee
                      </Button>
                    </Grid>
                  </>
                }
              </Grid>
            </Paper>
          </div>
          <div className={classes.contentMain} style={{ width: "100%" }}>
            <p>FAQ section</p>
            <Paper className={classes.paper} style={{ marginLeft: "16px" }}>
              <Grid
                container
                spacing={3}
                className="content"
                alignItems={"end"}
              >
                {
                  <>
                    {state.faq_info ? (
                      state.faq_info.map((ch, idx) => (
                        <>
                          <Grid item xs={7}>
                            <CustomInputField
                              label="Question"
                              fullWidth
                              value={ch.question}
                              name="name"
                              onChange={(e) => {
                                const updatedvar = [...state.faq_info];
                                updatedvar[idx].question = e.target.value;
                                setState({ ...state, faq_info: updatedvar });
                              }}
                            />
                          </Grid>
                          <Grid item xs={7}>
                            Answer
                            <Editor
                              label="Answer"
                              editorState={ch.answer}
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                              // value={state.country_description}

                              onEditorStateChange={(e) => {
                                const updatedvar = [...state.faq_info];
                                updatedvar[idx].answer = e;
                                setState({ ...state, faq_info: updatedvar });
                              }}
                              // onChange={(e) =>
                              // }}
                            />
                          </Grid>
                          <Grid
                            style={{ paddingTop: "64px", paddingLeft: "20px" }}
                          >
                            {" "}
                            <span className={classes.actionBtn}>
                              <CustomButton
                                variant="contained"
                                color="error"
                                onClick={(e) => {
                                  const newArray = [...state.faq_info];
                                  const itemToRemove = newArray[idx];

                                  if (itemToRemove.id !== "") {
                                    // Call your API here
                                    // Example:
                                    axios
                                      .post(
                                        url + "faqforvisa/deletefaqforvisa",
                                        {
                                          id: itemToRemove.id,
                                        },
                                        {
                                          headers: {
                                            /* Your headers here */
                                            "Content-Type": "application/json", // Example header
                                            Authorization: `Bearer ${token}`, // Example authorization header
                                          },
                                        }
                                      )
                                      .then((r) => {
                                        newArray.splice(idx, 1);
                                        setState({
                                          ...state,
                                          faq_info: newArray,
                                        });
                                      })
                                      .catch((error) => {
                                        console.error(
                                          "Error fetching data:",
                                          error
                                        );
                                        // Handle error here
                                      });
                                  } else {
                                    // If id is empty, directly remove the item from the array
                                    newArray.splice(idx, 1);
                                    setState({ ...state, faq_info: newArray });
                                  }
                                }}
                                icon={<FaTimes />}
                              />
                            </span>
                          </Grid>
                        </>
                      ))
                    ) : (
                      <p></p>
                    )}

                    <Grid item xs={12} md={4}>
                      <Button
                        style={{ marginTop: "18px" }}
                        variant="contained"
                        fullWidth
                        onClick={() => {
                          setState({
                            ...state,
                            faq_info: [
                              ...state.faq_info,
                              {
                                question: "",
                                answer: EditorState.createEmpty(),
                                id: "",
                              },
                            ],
                          });
                        }}
                      >
                        Add FAQ
                      </Button>
                    </Grid>
                  </>
                }
              </Grid>
            </Paper>
          </div>{" "}
          <div className={classes.contentMain} style={{ width: "100%" }}>
            <p>Partner section</p>
            <Paper className={classes.paper} style={{ marginLeft: "16px" }}>
              <Grid
                container
                spacing={3}
                className="content"
                alignItems={"end"}
              >
                <Grid item xs={7}>
                  <p>Logo</p>
                  <input
                    style={{
                      padding: "10px",
                      borderColor: "rgba(148,158,171,0.32)",
                      boxShadow: "1px 3px 10px rgba(0,0,0,0.17)",
                      width: "100%",
                      borderRadius: "12px",
                    }}
                    type="file"
                    multiple
                    fullWidth
                    label="logo Photos"
                    onChange={(e) => {
                      const files = Array.from(e.target.files);
                      console.log("Files array:", files); // Check if files are converted to an array
                      files.map((el, index) => {
                        var imageRef = ref(storage, `/logo+${Math.random()}`);
                        uploadBytes(imageRef, el).then(() => {
                          getDownloadURL(imageRef)
                            .then((url) => {
                              //console.log("Image URL:", url);
                              localStorage.setItem(`logo${index + 1}`, url);

                              set_img();
                            })
                            .catch((error) => {
                              //console.log(error);
                            });
                        });
                        function set_img() {
                          const data = [];
                          for (let i = 1; i <= files.length; i++) {
                            if (localStorage.getItem(`logo${i}`)) {
                              data.push(localStorage.getItem(`logo${i}`));
                            }
                          }
                          console.log(data);

                          setState({
                            ...state,
                            logo: data,
                          });
                        }
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}></Grid>
                <Grid item xs={12} style={{ display: "flex", gap: "20px" }}>
                  {logoPhotos != null
                    ? logoPhotos.map((el, idx) => (
                        <>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <img src={el} style={{ height: "50px" }} />
                            <CustomButton
                              variant="contained"
                              color="error"
                              onClick={() => removelogoPhotos(idx, "prev")}
                              icon={<FaTimes />}
                            />
                          </div>
                        </>
                      ))
                    : console.log(logoPhotos)}

                  {state.logo != null
                    ? state.logo.map((el, idx) => (
                        <>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <img src={el} style={{ height: "50px" }} />
                            <CustomButton
                              variant="contained"
                              color="error"
                              onClick={() => removelogoPhotos(idx, "new")}
                              icon={<FaTimes />}
                            />
                          </div>
                        </>
                      ))
                    : console.log(state.logo)}
                </Grid>
              </Grid>
            </Paper>
          </div>{" "}
          <div className={classes.contentMain} style={{ width: "100%" }}>
            <p>Customer Testimonial section</p>
            <Paper className={classes.paper} style={{ marginLeft: "16px" }}>
              <Grid
                container
                spacing={3}
                className="content"
                alignItems={"end"}
              >
                {
                  <>
                    {state.customer_testimonial ? (
                      state.customer_testimonial.map((ch, idx) => (
                        <>
                          <Grid item xs={7}>
                            <CustomInputField
                              label="Name"
                              fullWidth
                              value={ch.name}
                              name="name"
                              onChange={(e) => {
                                const updatedvar = [
                                  ...state.customer_testimonial,
                                ];
                                updatedvar[idx].name = e.target.value;
                                setState({
                                  ...state,
                                  customer_testimonial: updatedvar,
                                });
                              }}
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <CustomInputField
                              fullWidth
                              label="Customer Photo"
                              type="file"
                              onChange={(e) => {
                                const imageRef = ref(
                                  storage,
                                  `/customers+${Math.random()}`
                                );
                                uploadBytes(imageRef, e.target.files[0]).then(
                                  () => {
                                    getDownloadURL(imageRef)
                                      .then((url) => {
                                        // link = url;
                                        //console.log("Image URL:", url);

                                        localStorage.setItem("customer", url);
                                        const updatedvar = [
                                          ...state.customer_testimonial,
                                        ];
                                        updatedvar[idx].image =
                                          localStorage.getItem("customer");
                                        setState({
                                          ...state,
                                          customer_testimonial: updatedvar,
                                        });
                                        // handleSaveVisaCategory();
                                        // setState({
                                        //   ...state,
                                        //   image:
                                        //     localStorage.getItem("customer"),
                                        // });
                                      })
                                      .catch((error) => {
                                        console.log(error);
                                      });
                                  }
                                );
                              }}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            {ch.image && (
                              <>
                                <p> image</p>
                                <Avatar
                                  variant="rounded"
                                  alt="VISA"
                                  src={ch.image}
                                  style={{
                                    margin: "auto",
                                  }}
                                />
                              </>
                            )}
                          </Grid>
                          <Grid item xs={7}>
                            <CustomInputField
                              type="date"
                              label="Date"
                              fullWidth
                              value={ch.date}
                              name="name"
                              onChange={(e) => {
                                const updatedvar = [
                                  ...state.customer_testimonial,
                                ];
                                updatedvar[idx].date = e.target.value;
                                setState({
                                  ...state,
                                  customer_testimonial: updatedvar,
                                });
                              }}
                            />
                          </Grid>
                          <Grid item xs={7}>
                            <CustomInputField
                              fullWidth
                              label=" Testimonial Title"
                              value={ch.title}
                              name="name"
                              onChange={(e) => {
                                const updatedvar = [
                                  ...state.customer_testimonial,
                                ];
                                updatedvar[idx].title = e.target.value;
                                setState({
                                  ...state,
                                  customer_testimonial: updatedvar,
                                });
                              }}
                            />
                          </Grid>
                          <Grid item xs={7}>
                            <CustomInputField
                              fullWidth
                              label="Location"
                              value={ch.location}
                              name="name"
                              onChange={(e) => {
                                const updatedvar = [
                                  ...state.customer_testimonial,
                                ];
                                updatedvar[idx].location = e.target.value;
                                setState({
                                  ...state,
                                  customer_testimonial: updatedvar,
                                });
                              }}
                            />
                          </Grid>
                          <Grid item xs={7}>
                            <CustomInputField
                              fullWidth
                              type="number"
                              label="Rating"
                              value={ch.rating}
                              name="name"
                              onChange={(e) => {
                                const updatedvar = [
                                  ...state.customer_testimonial,
                                ];
                                updatedvar[idx].rating = e.target.value;
                                setState({
                                  ...state,
                                  customer_testimonial: updatedvar,
                                });
                              }}
                            />
                          </Grid>
                          <Grid item xs={7}>
                            Description
                            <Editor
                              label="Answer"
                              editorState={ch.description}
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                              // value={state.country_description}

                              onEditorStateChange={(e) => {
                                const updatedvar = [
                                  ...state.customer_testimonial,
                                ];
                                updatedvar[idx].description = e;
                                setState({
                                  ...state,
                                  customer_testimonial: updatedvar,
                                });
                              }}
                              // onChange={(e) =>
                              // }}
                            />
                          </Grid>
                          <Grid
                            style={{ paddingTop: "64px", paddingLeft: "20px" }}
                          >
                            {" "}
                            <span className={classes.actionBtn}>
                              <CustomButton
                                variant="contained"
                                color="error"
                                onClick={(e) => {
                                  const newArray = [
                                    ...state.customer_testimonial,
                                  ];
                                  const itemToRemove = newArray[idx];

                                  if (itemToRemove.id !== "") {
                                    // Call your API here
                                    // Example:
                                    axios
                                      .post(
                                        url +
                                          "customertestimonial/deletecustomertestimonial",
                                        {
                                          id: itemToRemove.id,
                                        },
                                        {
                                          headers: {
                                            /* Your headers here */
                                            "Content-Type": "application/json", // Example header
                                            Authorization: `Bearer ${token}`, // Example authorization header
                                          },
                                        }
                                      )
                                      .then((r) => {
                                        newArray.splice(idx, 1);
                                        setState({
                                          ...state,
                                          customer_testimonial: newArray,
                                        });
                                      })
                                      .catch((error) => {
                                        console.error(
                                          "Error fetching data:",
                                          error
                                        );
                                        // Handle error here
                                      });
                                  } else {
                                    // If id is empty, directly remove the item from the array
                                    newArray.splice(idx, 1);
                                    setState({
                                      ...state,
                                      customer_testimonial: newArray,
                                    });
                                  }
                                }}
                                icon={<FaTimes />}
                              />
                            </span>
                          </Grid>
                        </>
                      ))
                    ) : (
                      <p></p>
                    )}

                    <Grid item xs={12} md={4}>
                      <Button
                        style={{ marginTop: "18px" }}
                        variant="contained"
                        fullWidth
                        onClick={() => {
                          setState({
                            ...state,
                            customer_testimonial: [
                              ...state.customer_testimonial,
                              {
                                id: "",
                                name: "",
                                image: "",
                                description: EditorState.createEmpty(),
                                date: "",
                                location: "",
                                title: "",
                                rating: "",
                              },
                            ],
                          });
                        }}
                      >
                        Add Testimonial
                      </Button>
                    </Grid>
                  </>
                }
              </Grid>
            </Paper>
          </div>{" "}
          <div className={classes.contentMain} style={{ width: "100%" }}>
            <p>Document section</p>
            <Paper className={classes.paper} style={{ marginLeft: "16px" }}>
              <Grid
                container
                spacing={3}
                className="content"
                alignItems={"end"}
              >
                {
                  <>
                    {state.docs ? (
                      state.docs.map((ch, idx) => (
                        <>
                          <Grid item xs={7}>
                            <CustomInputField
                              label="Document title"
                              fullWidth
                              value={ch.docs_title}
                              name="name"
                              onChange={(e) => {
                                const updatedvar = [...state.docs];
                                updatedvar[idx].docs_title = e.target.value;
                                setState({
                                  ...state,
                                  docs: updatedvar,
                                });
                              }}
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <CustomInputField
                              fullWidth
                              label="Document icon"
                              type="file"
                              onChange={(e) => {
                                const imageRef = ref(
                                  storage,
                                  `/customers+${Math.random()}`
                                );
                                uploadBytes(imageRef, e.target.files[0]).then(
                                  () => {
                                    getDownloadURL(imageRef)
                                      .then((url) => {
                                        // link = url;
                                        //console.log("Image URL:", url);
                                        const updatedvar = [...state.docs];
                                        updatedvar[idx].docs_icon = url;
                                        setState({
                                          ...state,
                                          docs: updatedvar,
                                        });
                                        // handleSaveVisaCategory();
                                        // setState({
                                        //   ...state,
                                        //   image:
                                        //     localStorage.getItem("customer"),
                                        // });
                                      })
                                      .catch((error) => {
                                        console.log(error);
                                      });
                                  }
                                );
                              }}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            {ch.docs_icon && (
                              <>
                                <p> image</p>
                                <Avatar
                                  variant="rounded"
                                  alt="VISA"
                                  src={ch.docs_icon}
                                  style={{
                                    margin: "auto",
                                  }}
                                />
                              </>
                            )}
                          </Grid>
                          <Grid
                            style={{ paddingTop: "64px", paddingLeft: "20px" }}
                          >
                            {" "}
                            <span className={classes.actionBtn}>
                              <CustomButton
                                variant="contained"
                                color="error"
                                onClick={(e) => {
                                  const newArray = [...state.docs];
                                  const itemToRemove = newArray[idx];

                                  if (itemToRemove.docs_id) {
                                    // Call your API here
                                    // Example:
                                    axios
                                      .post(
                                        url +
                                          "documentrequired/deletedocumentrequired",
                                        {
                                          id: itemToRemove.docs_id,
                                        },
                                        {
                                          headers: {
                                            "Content-Type": "application/json",
                                            Authorization: `Bearer ${token}`,
                                          },
                                        }
                                      )
                                      .then((response) => {
                                        console.log(
                                          "API response:",
                                          response.data
                                        );
                                        // Remove item from the array
                                        newArray.splice(idx, 1);
                                        // Update state
                                        setState({
                                          ...state,
                                          docs: newArray,
                                        });
                                        console.log("Updated state:", newArray);
                                      })
                                      .catch((error) => {
                                        console.error(
                                          "Error deleting document:",
                                          error
                                        );
                                      });
                                  } else {
                                    // If id is empty, directly remove the item from the array
                                    newArray.splice(idx, 1);
                                    // Update state
                                    setState({
                                      ...state,
                                      docs: newArray,
                                    });
                                    console.log("Updated state:", newArray);
                                  }
                                }}
                                icon={<FaTimes />}
                              />
                            </span>
                          </Grid>
                        </>
                      ))
                    ) : (
                      <p></p>
                    )}

                    <Grid item xs={12} md={4}>
                      <Button
                        style={{ marginTop: "18px" }}
                        variant="contained"
                        fullWidth
                        onClick={() => {
                          setState({
                            ...state,
                            docs: [
                              ...state.docs,
                              {
                                docs_icon: "",
                                docs_title: "",
                                docs_id: "",
                              },
                            ],
                          });
                        }}
                      >
                        Add Document
                      </Button>
                    </Grid>
                  </>
                }
              </Grid>
            </Paper>
          </div>{" "}
          <div className={classes.contentMain} style={{ width: "100%" }}>
            <p>Immigration section</p>
            <Paper className={classes.paper} style={{ marginLeft: "16px" }}>
              <Grid
                container
                spacing={3}
                className="content"
                alignItems={"end"}
              >
                {
                  <>
                    {state.immigration ? (
                      state.immigration.map((ch, idx) => (
                        <>
                          <Grid item xs={7}>
                            <CustomInputAndSelectField
                              options={["Immigration", "VisaRoot officer"]}
                              value={
                                ch.type == 1
                                  ? "Immigration"
                                  : ch.type == 2
                                  ? "VisaRoot officer"
                                  : ""
                              }
                              changeCallBack={(e, v) => {
                                const updatedvar = [...state.immigration];

                                if (v == "Immigration") {
                                  updatedvar[idx].type = 1;
                                  setState({
                                    ...state,
                                    immigration: updatedvar,
                                  });
                                }
                                if (v == "VisaRoot officer") {
                                  updatedvar[idx].type = 2;
                                  setState({
                                    ...state,
                                    immigration: updatedvar,
                                  });
                                }
                              }}
                              label="Officer Type"
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={7}>
                            <CustomInputField
                              fullWidth
                              label="Officer position"
                              value={ch.position}
                              onChange={(e) => {
                                const updatedvar = [...state.immigration];
                                updatedvar[idx].position = e.target.value;
                                setState({
                                  ...state,
                                  immigration: updatedvar,
                                });
                              }}
                            />
                          </Grid>
                          <Grid item xs={7}>
                            <CustomInputField
                              fullWidth
                              label="Officer Name"
                              value={ch.name}
                              onChange={(e) => {
                                const updatedvar = [...state.immigration];
                                updatedvar[idx].name = e.target.value;
                                setState({
                                  ...state,
                                  immigration: updatedvar,
                                });
                              }}
                            />
                          </Grid>

                          <Grid item xs={7}>
                            <CustomInputField
                              fullWidth
                              label="Officer photo"
                              type="file"
                              onChange={(e) => {
                                const imageRef = ref(
                                  storage,
                                  `/officers+${Math.random()}`
                                );
                                uploadBytes(imageRef, e.target.files[0]).then(
                                  () => {
                                    getDownloadURL(imageRef)
                                      .then((url) => {
                                        // link = url;
                                        //console.log("Image URL:", url);
                                        // handleSaveVisaCategory();
                                        const updatedvar = [
                                          ...state.immigration,
                                        ];
                                        updatedvar[idx].image = url;
                                        setState({
                                          ...state,
                                          immigration: updatedvar,
                                        });
                                      })
                                      .catch((error) => {
                                        console.log(error);
                                      });
                                  }
                                );
                              }}
                            />
                          </Grid>
                          <Grid item xs={7}>
                            {ch.image && (
                              <>
                                <p> image</p>
                                <Avatar
                                  variant="rounded"
                                  alt="VISA"
                                  src={ch.image}
                                  style={{
                                    margin: "auto",
                                  }}
                                />
                              </>
                            )}
                          </Grid>
                          <Grid
                            style={{ paddingTop: "64px", paddingLeft: "20px" }}
                          >
                            {" "}
                            <span className={classes.actionBtn}>
                              <CustomButton
                                variant="contained"
                                color="error"
                                onClick={(e) => {
                                  const newArray = [...state.immigration];
                                  const itemToRemove = newArray[idx];

                                  if (itemToRemove.id) {
                                    // Call your API here
                                    // Example:
                                    axios
                                      .post(
                                        url +
                                          "immigrationofficerlist/deleteimmigrationofficerlist",
                                        {
                                          id: itemToRemove.id,
                                        },
                                        {
                                          headers: {
                                            "Content-Type": "application/json",
                                            Authorization: `Bearer ${token}`,
                                          },
                                        }
                                      )
                                      .then((response) => {
                                        console.log(
                                          "API response:",
                                          response.data
                                        );
                                        // Remove item from the array
                                        newArray.splice(idx, 1);
                                        // Update state
                                        setState({
                                          ...state,
                                          immigration: newArray,
                                        });
                                        console.log("Updated state:", newArray);
                                      })
                                      .catch((error) => {
                                        console.error(
                                          "Error deleting document:",
                                          error
                                        );
                                      });
                                  } else {
                                    // If id is empty, directly remove the item from the array
                                    newArray.splice(idx, 1);
                                    // Update state
                                    setState({
                                      ...state,
                                      immigration: newArray,
                                    });
                                    console.log("Updated state:", newArray);
                                  }
                                }}
                                icon={<FaTimes />}
                              />
                            </span>
                          </Grid>
                        </>
                      ))
                    ) : (
                      <p></p>
                    )}

                    <Grid item xs={12} md={4}>
                      <Button
                        style={{ marginTop: "18px" }}
                        variant="contained"
                        fullWidth
                        onClick={() => {
                          setState({
                            ...state,
                            immigration: [
                              ...state.immigration,
                              {
                                position: "",
                                image: "",
                                name: "",
                                type: "",
                                id: "",
                              },
                            ],
                          });
                        }}
                      >
                        Add Immigration
                      </Button>
                    </Grid>
                  </>
                }
              </Grid>
            </Paper>
          </div>{" "}
          <div className={classes.contentMain} style={{ width: "100%" }}>
            <p>Additional Question</p>
            <Paper className={classes.paper} style={{ marginLeft: "16px" }}>
              <Grid
                container
                spacing={3}
                className="content"
                alignItems={"end"}
              >
                {state.question_info ? (
                  state.question_info.map((ch, idx) => (
                    <>
                      <Grid item xs={12}>
                        <CustomInputAndSelectField
                          options={ch.type_list.map((item) => item)} // Set options from entry type names
                          value={ch.question_type}
                          label="Question type"
                          fullWidth
                          changeCallBack={(e, v) => {
                            console.log(ch);
                            const idxSelect = ch.type_list.indexOf(v);
                            setState((prevState) => {
                              const newQuestionInfo =
                                prevState.question_info.map((info, index) => {
                                  if (index === idx) {
                                    let updatedInfo = {};
                                    const multipleChoiceTypes = [
                                      "Drop Down",
                                      "Check box",
                                      "Radio",
                                    ];
                                    const selectedType = idxSelect + 1;
                                    const needsChoices =
                                      multipleChoiceTypes.includes(v) ||
                                      [7, 8, 9].includes(selectedType);

                                    if (needsChoices) {
                                      updatedInfo = {
                                        ...info,
                                        type_question: "multiple",
                                        question_type: v,
                                        question_type_id: selectedType,
                                        choice:
                                          info.choice.length > 0
                                            ? info.choice
                                            : [{ choice_title: "" }],
                                      };
                                    } else {
                                      updatedInfo = {
                                        ...info,
                                        type_question: "",
                                        question_type: v,
                                        question_type_id: selectedType,
                                        choice: info.choice,
                                      };
                                    }

                                    return updatedInfo;
                                  }
                                  return info;
                                });

                              return {
                                ...prevState,
                                question_info: newQuestionInfo,
                              };
                            });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CustomInputField
                          label="Question"
                          fullWidth
                          value={ch.name}
                          name="name"
                          onChange={(e) => {
                            const newQuestionInfo = [...state.question_info];
                            newQuestionInfo[idx].name = e.target.value;
                            setState({
                              ...state,
                              question_info: newQuestionInfo,
                            });
                          }}
                        />
                      </Grid>

                      {ch.type_question ? (
                        <>
                          {ch.choice.map((ch2, idx2) => (
                            <>
                              <Grid item xs={7}>
                                <CustomInputField
                                  label="Choice"
                                  fullWidth
                                  value={ch2.choice_title}
                                  name="name"
                                  onChange={(e) => {
                                    const newQuestionInfo = [
                                      ...state.question_info,
                                    ];
                                    newQuestionInfo[idx].choice[
                                      idx2
                                    ].choice_title = e.target.value;
                                    setState({
                                      ...state,
                                      question_info: newQuestionInfo,
                                    });
                                  }}
                                />
                              </Grid>
                              <Grid
                                style={{
                                  paddingTop: "64px",
                                  paddingLeft: "20px",
                                }}
                              >
                                <span className={classes.actionBtn}>
                                  <CustomButton
                                    variant="contained"
                                    color="error"
                                    onClick={() => {
                                      const newQuestionInfo = [
                                        ...state.question_info,
                                      ];
                                      newQuestionInfo[idx].choice.splice(
                                        idx2,
                                        1
                                      );
                                      setState({
                                        ...state,
                                        question_info: newQuestionInfo,
                                      });
                                    }}
                                    icon={<FaTimes />}
                                  />
                                </span>
                              </Grid>
                            </>
                          ))}

                          <Grid item xs={12} md={4}>
                            <Button
                              style={{ marginTop: "18px" }}
                              variant="contained"
                              fullWidth
                              onClick={() => {
                                const newQuestionInfo = [
                                  ...state.question_info,
                                ];
                                newQuestionInfo[idx].choice.push({
                                  choice_title: "",
                                });
                                setState({
                                  ...state,
                                  question_info: newQuestionInfo,
                                });
                              }}
                            >
                              Add Choice
                            </Button>
                          </Grid>
                        </>
                      ) : (
                        <p></p>
                      )}
                      <Grid item xs={3}>
                        <p>Required</p>
                        <ToggleButtonGroup
                          color="warning"
                          value={ch.required ? "True" : "False"}
                          exclusive
                          onChange={(e, newAlignment) => {
                            const newQuestionInfo = [...state.question_info];
                            newQuestionInfo[idx].required =
                              newAlignment === "True";
                            setState({
                              ...state,
                              question_info: newQuestionInfo,
                            });
                          }}
                          aria-label="Required"
                        >
                          <ToggleButton value="True" style={{ color: "black" }}>
                            Yes
                          </ToggleButton>
                          <ToggleButton
                            value="False"
                            style={{ color: "black" }}
                          >
                            No
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Grid>

                      <Grid style={{ paddingTop: "64px", paddingLeft: "20px" }}>
                        {" "}
                        <span className={classes.actionBtn}>
                          <CustomButton
                            variant="contained"
                            color="error"
                            onClick={() => {
                              if (ch.id) {
                                try {
                                  const loginHeaders = new Headers();
                                  loginHeaders.append(
                                    "Content-Type",
                                    "application/json"
                                  );

                                  // Assuming you have an authorization token stored in localStorage
                                  const authToken = localStorage.getItem("tkn");
                                  if (authToken) {
                                    loginHeaders.append(
                                      "Authorization",
                                      `Bearer ${authToken}`
                                    );
                                  }
                                  const data = { id: ch.id };
                                  const requestOptions = {
                                    method: "POST",
                                    headers: loginHeaders,
                                    body: JSON.stringify(data),
                                  };
                                  fetch(
                                    url + "question/deletequestion",
                                    requestOptions
                                  )
                                    .then((res) => res.json())
                                    .then((actualData) => {
                                      console.log(actualData);
                                      // After successful deletion, update the state
                                      const newArray = [...state.question_info];
                                      newArray.splice(idx, 1);
                                      setState({
                                        ...state,
                                        question_info: newArray,
                                      });
                                    })
                                    .catch((err) => {
                                      console.log(err);
                                    });
                                } catch (err) {
                                  console.log(err);
                                }
                              } else {
                                // If id is empty, directly remove the item from the array
                                const newArray = [...state.question_info];
                                newArray.splice(idx, 1);
                                setState({ ...state, question_info: newArray });
                              }
                            }}
                            icon={<FaTimes />}
                          />
                        </span>
                      </Grid>
                    </>
                  ))
                ) : (
                  <p></p>
                )}

                <Grid item xs={12} md={4}>
                  <Button
                    style={{ marginTop: "18px" }}
                    variant="contained"
                    fullWidth
                    onClick={() => {
                      setState({
                        ...state,
                        question_info: [
                          ...state.question_info,
                          {
                            question_type: "",
                            question_type_id: "",
                            type_question: "",
                            type_list: [
                              "text input",
                              "Number",
                              "Upload",
                              "Date Picker",
                              "Toggle",
                              "Text Area",
                              "Drop Down",
                              "Check box",
                              "Radio",
                            ],
                            name: "",
                            choice: [],
                          },
                        ],
                      });
                    }}
                  >
                    Add Question
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </div>
          {/* <div className={classes.contentMain} style={{ width: "100%" }}>
            <Paper className={classes.paper} style={{ marginLeft: "16px" }}>
              <Grid
                container
                spacing={3}
                className="content"
                alignItems={"end"}
              ></Grid>
            </Paper>
          </div> */}
          <Grid item xs={12}>
            {state.isUpdate ? (
              <Button
                variant="contained"
                fullWidth
                color="warning"
                onClick={(e) => {
                  handleUpdateInfo(state.visaId);
                }}
              >
                Update
              </Button>
            ) : (
              <Button
                variant="contained"
                fullWidth
                onClick={(e) => {
                  handleCreateInfo();
                }}
              >
                Create
              </Button>
            )}
          </Grid>
          <ToastContainer />
        </Grid>
      </div>
    </div>
  );
}
