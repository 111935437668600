import React, { useState } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router";
import { FaEllipsisV } from "react-icons/fa";
import url from "../../API";
import { TbPasswordFingerprint } from "react-icons/tb";
import { FaEye } from "react-icons/fa";
import { MdBlockFlipped } from "react-icons/md";


const MenuButton = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [statusMenuAnchorEl, setStatusMenuAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setStatusMenuAnchorEl(null);
  };

  return (
    <>
      <FaEllipsisV
        size={20}
        style={{ cursor: "pointer" }}
        onClick={handleClick}
      />
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem><TbPasswordFingerprint style={{fontSize:24, marginRight:5}}/>View Password</MenuItem>
        <MenuItem><FaEye style={{fontSize:20, marginRight:5}} />Change Password</MenuItem>
        <MenuItem><MdBlockFlipped style={{fontSize:20, marginRight:5}} />Block User</MenuItem>
        {/* <MenuItem onClick={handleFaqClick}>Faq section</MenuItem>
        <MenuItem onClick={handlePartnerClick}>
          Partner we work with section
        </MenuItem>

        <MenuItem onClick={handleCustomerClick}>
          Customer Testimonial section
        </MenuItem>
        <MenuItem onClick={handleDocsClick}>Document section</MenuItem>
        <MenuItem onClick={handleImmigrationClick}>
          Immigration officier section
        </MenuItem>
        <MenuItem onClick={handleQuestionListClick}>
          Additional Question list
        </MenuItem> */}
      </Menu>
    </>
  );
};

export default MenuButton;
