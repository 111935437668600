import React from "react";
import { AiFillFlag, AiFillHome } from "react-icons/ai";
import {
  FaDashcube,
  FaPoll,
  FaPortrait,
  FaScroll,
  FaPassport,
  FaTag,
  FaBalanceScale,
  FaBook,
  FaDoorOpen,
  FaUserShield,
  FaComments,
  FaCreditCard,
  FaQuestion,
  FaHourglassHalf,
  FaFolderOpen,
  FaQuestionCircle,
  FaNewspaper,
  FaDollarSign,
  FaFileSignature,
  FaList,
  FaBlog,
  FaSearch,
  FaTicketAlt,
  FaFile,
  FaCog,
  FaAddressBook,
  FaHandshake,
} from "react-icons/fa";
import { MdOutlineSecurity } from "react-icons/md";


// ----------------------------------------------------------------------

// const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    value: 0,
    name: "dashboard",
    title: <>Dashboard</>,
    path: "/dashboard",
    icon: <AiFillHome className="sidebar-icon" />,
  },
  // {
  //   value: 1,
  //   name: "newOffers",
  //   title: <>Applications </>,
  //   path: "/country_list2",
  //   icon: <FaList className="sidebar-icon" />,
  //   children: [
  //     {
  //       value: 7,
  //       name: "jobApplication",
  //       title: <>Pending Application </>,
  //       path: "/pending_list",
  //     },
  //     {
  //       value: 8,
  //       name: "jobCreate",
  //       title: <>Approved Application</>,
  //       path: "/application_list",
  //     },
  //     {
  //       value: 7,
  //       name: "jobApplication",
  //       title: <>Rejected Application </>,
  //       path: "/rejected_list",
  //     },
  //   ],
  // },
  {
    value: 7,
    name: "jobApplication",
    title: <>Pending Application </>,
    path: "/pending_list",
    icon: <FaList className="sidebar-icon" />,
  },
  {
    value: 8,
    name: "jobCreate",
    title: <>Approved Application</>,
    path: "/application_list",
    icon: <FaList className="sidebar-icon" />,
  },
  {
    value: 9,
    name: "jobApplication",
    title: <>Rejected Application </>,
    path: "/rejected_list",
    icon: <FaList className="sidebar-icon" />,
  },
  {
    value: 2,
    name: "beneficiarylist",
    title: <>Beneficiary List</>,
    path: "/beneficiary_list",
    icon: <FaHandshake className="sidebar-icon" />,
  },
  {
    value: 5,
    name: "visaCategory",
    title: <>District Authorization</>,
    path: "/District_Authority_List",
    icon: <MdOutlineSecurity className="sidebar-icon" />,
  },
  {
    value: 12,
    name: "newOffers",
    title: <>Block Authorization</>,
    path: "/Block_Autority_List",
    icon: <MdOutlineSecurity className="sidebar-icon" />,
  },
];

export const bottomNavigationConfig = [
  {
    value: 0,
    name: "home",
    title: <>Home</>,
    path: "/dashboard",
    icon: <AiFillHome className="sidebar-icon" />,
  },
  {
    value: 1,
    name: "dashboard",
    title: <>Dashboard</>,
    path: "/dashboard",
    icon: <FaDashcube className="sidebar-icon" />,
  },
  {
    value: 2,
    name: "profile",
    title: (
      <>
        Profile <span className="alert-helper-ylw">Imp</span>
      </>
    ),
    path: "/profile",
    icon: <FaPortrait className="sidebar-icon" />,
  },
  {
    value: 3,
    name: "jobfeed",
    title: (
      <>
        JobFeed <span className="alert-helper">New</span>
      </>
    ),
    path: "/jobfeed",
    icon: <FaScroll className="sidebar-icon" />,
  },
  {
    value: 4,
    name: "resources",
    title: <>Resources</>,
    path: "/resource",
    icon: <FaPoll className="sidebar-icon" />,
  },
];
export default sidebarConfig;
