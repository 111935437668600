import { TextField, Box, Button, Typography } from "@mui/material";
import classNames from "classnames";
import React, { useState } from "react";
import customInputStyles from "../../styles/components/CustomInputStyle/index.styles";

export default function CustomInputField({
  startAdornment,
  endAdornment,
  required,
  type,
  label,
  onChange,
  ...props
}) {
  const classes = customInputStyles();
  const [fileName, setFileName] = useState("No file chosen");

  const handleFileChange = (e) => {
    if (type === "file") {
      const file = e.target.files[0];
      if (file) {
        setFileName(file.name);
        onChange(e);
      } else {
        setFileName("No file chosen");
      }
    } else {
      onChange(e);
    }
  };

  if (type === "file" || type === "File") {
    return (
      <>
        {label && <p style={{ textAlign: "left", margin: 0 }}>{label}</p>}
        <Box
          display="flex"
          alignItems="center"
          style={{
            padding: "4px",
            borderColor: "rgba(148,158,171,0.32)",
            boxShadow: "1px 3px 10px rgba(0,0,0,0.17)",
            width: "100%",
            borderRadius: "12px",
          }}
          className={classNames(classes.root, props.className)}
        >
          <Button
            variant="contained"
            component="label"
            style={{
              marginRight: "1rem",
              backgroundColor: "#f0f0f0",
              color: "#27282C",
              fontWeight: "500",
              boxShadow: "none",
            }}
          >
            Choose File
            <input
              type="file"
              hidden
              onChange={handleFileChange}
              accept="image/*"
            />
          </Button>
          <span style={{ color: "#27282C", fontWeight: "500" }}>
            {fileName}
          </span>
        </Box>
      </>
    );
  }

  return (
    <>
      {label && <p style={{ textAlign: "left", margin: 0 }}>{label}</p>}
      <TextField
        {...props}
        type={type}
        size="small"
        className={
          type === "textarea"
            ? classNames(props.className, "text-area")
            : classNames(classes.root, props.className)
        }
        InputLabelProps={{
          shrink: false,
          className: classes.inputLabelNoShrink,
        }}
        value={props.value}
        required={required}
        InputProps={{
          startAdornment: startAdornment,
          classes: {
            notchedOutline: classes.notchedOutline,
          },
          endAdornment: endAdornment,
          ...props.InputProps,
        }}
        onChange={handleFileChange}
      />
    </>
  );
}
