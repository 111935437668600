import { Divider, Grid, Menu, MenuItem, Typography } from "@mui/material";
import React, { useState } from "react";
import { AiFillHome } from "react-icons/ai";
import {
  FaArrowRight,
  FaHandshake,
  FaThumbtack,
  FaRupeeSign,
  FaPaperclip,
  FaPaperPlane,
  FaSignInAlt,
  FaUser,
  FaTimesCircle,
  FaHourglassHalf,
  FaCheckCircle,
  FaUserFriends,
  FaThumbsDown
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "../../layouts";
import useStyles from "../../styles/pages/Dashboard/index.style";
import AccountCard from "./component/card";
import axios from "axios";
import endPoints from "../../config/endPoint";
import DashboardCard from "./component/baseCard";
// import CustomDateRangePicker from "../../components/CustomDateRange";
import RevenueChart from "./component/chart";
import CustomDropdownSelector from "../../components/CustomDateRange";
import { MdLogout } from "react-icons/md";
import { GoDotFill } from "react-icons/go";
import NotificationBtn from "../../components/Notification_btn/Index";
import { IoMail } from "react-icons/io5";
import { Search } from "@mui/icons-material";
import { IoNotificationsSharp } from "react-icons/io5";
import {
  MdOutlineNotificationsActive,
  MdMessage,
  MdPersonAdd
} from "react-icons/md";

import { CiMail } from "react-icons/ci";

export default function DashBoard() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [date, setDate] = React.useState({
    startDate: null,
    endDate: null
  });

  const token = localStorage.getItem("token");
  const [countState, setCountState] = React.useState({
    coupon: "N/A",
    payouts: "N/A"
  });
  React.useEffect(() => {
    axios
      .post(endPoints.getDashboardDetails, { token })
      .then(r => setCountState(r.data.results));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [notifications, setNotifications] = useState([
    { message: "23 people applied recently", icon: <CiMail /> },
    { message: "10 new messages", icon: <CiMail /> },
    { message: "5 people has been rejected", icon: <CiMail /> },
    {
      message: "You have a new offer.",
      icon: <CiMail />
    },
    { message: "200 people got his voucher", icon: <CiMail /> }
  ]);

  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 20
            }}
          >
            <div>
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  outline: "none",
                  borderRadius: "0.75rem",
                  border: "2px solid #e5e7eb"
                }}
              >
                <Search
                  style={{
                    fontSize: "1.5rem",
                    color: "#a1a1aa",
                    position: "absolute",
                    top: "0.675rem",
                    left: "0.875rem",
                    display: "flex",
                    alignItems: "center"
                  }}
                />
                <input
                  type="text"
                  inputMode="text"
                  style={{
                    width: "16vw",
                    maxWidth: "100%",
                    outline: "none",
                    borderRadius: "0.75rem",
                    border: "2px solid #e5e7eb",
                    padding: "0.75rem 0.75rem 0.75rem 3rem",
                    fontWeight: "500",
                    // boxShadow:
                    //   "0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)",
                    transition: "all 0.2s",
                    placeholderColor: "#4b5563",
                    borderColor: "transparent"
                  }}
                  placeholder="Search here..."
                  // onFocus={e => {
                  //   e.target.style.borderColor = "#3b82f6";
                  //   e.target.style.boxShadow = "none";
                  // }}
                  // onBlur={e => {
                  //   e.target.style.borderColor = "transparent";
                  // }}
                />
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: 24 }}>
              <div>
                <IoMail
                  style={{
                    color: "#aaa",
                    fontSize: 30,
                    marginTop: 10,
                    cursor: "pointer"
                  }}
                  onClick={handleClick}
                />
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left"
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                  }}
                  PaperProps={{
                    style: {
                      padding: "10px",
                      borderRadius: "10px",
                      boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                      width: "300px",
                      marginTop: "30px",
                      marginLeft: "30px"
                    }
                  }}
                >
                  <p style={{ fontWeight: "bold", margin: "10px 0" }}>
                    Notifications
                  </p>
                  <div
                    style={{
                      height: "1px",
                      width: "100%",
                      backgroundColor: "#EEEEEE",
                      marginBottom: "10px"
                    }}
                  />
                  {notifications.map((notification, index) =>
                    <MenuItem
                      key={index}
                      style={{
                        padding: "10px 20px",
                        borderBottom:
                          index !== notifications.length - 1
                            ? "1px solid #EEEEEE"
                            : "none",
                        backgroundColor:
                          index % 2 === 0 ? "#f9f9f9" : "#ffffff",
                        display: "flex",
                        alignItems: "center"
                      }}
                    >
                      <span style={{ marginRight: "10px", color: "#3f51b5" }}>
                        {notification.icon}
                      </span>
                      {notification.message}
                    </MenuItem>
                  )}
                </Menu>
              </div>
              <div style={{ marginTop: 10 }}>
                <NotificationBtn />
              </div>
              <div style={{ display: "flex", alignItems: "end" }}>
                <img
                  src="https://th.bing.com/th/id/OIP.JkiKVe6uSDhUMZY_BgVK0QHaHa?w=500&h=500&rs=1&pid=ImgDetMain"
                  style={{ borderRadius: "50%", width: "40px", height: "40px" }}
                />
                <p>Hi, Admin</p>
              </div>
            </div>
          </div>
        </div>
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
          }}
        >
          <div>
            {" "}<Typography variant="h3" color={"primary"}>
              <AiFillHome style={{ marginTop: -10 }} /> DashBoard
            </Typography>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "30px",
              alignItems: "center"
            }}
          >
            {/* {" "}<NotificationBtn /> */}
            <Typography
              onClick={() => {
                localStorage.clear();
                navigate("/");
              }}
              variant="h4"
              color="primary"
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer"
              }}
            >
              Log out
              <MdLogout size={30} style={{ marginLeft: "8px" }} />
            </Typography>
          </div>
        </div>

        <Divider className={classes.divider} />
        <section
          style={{
            borderRadius: "0.5rem",
            position: "relative"
          }}
        >
          <div style={{ position: "absolute", zIndex: 10, display: "flex" }}>
            <div
              style={{
                backgroundColor: "#F47216",
                width: "8.8vw",
                paddingLeft: "1rem",
                paddingTop: "0.75rem",
                paddingBottom: "0.75rem",
                transform: "skewX(-20deg)"
              }}
            >
              <p
                style={{
                  color: "white",
                  textAlign: "start",
                  fontSize: "16px",
                  fontWeight: "normal"
                }}
              >
                What's New
              </p>
            </div>
            {/* <div style={{ width: '2vw', height: '3vw', backgroundColor: '#F47216', transform: 'skewX(-20deg)', marginLeft: '-1vw' }} /> */}
          </div>
          <div
            style={{
              backgroundColor: "#F9F9F9",
              paddingLeft: "1rem",
              paddingRight: "1rem",
              paddingTop: "0.75rem",
              paddingBottom: "0.75rem",
              transform: "skewX(-20deg)"
            }}
          >
            <marquee behavior="scroll" direction="left">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "0.5rem",
                  paddingRight: "0.5rem",
                  marginLeft: "0.75rem",
                  marginRight: "0.75rem"
                }}
              >
                <div style={{ marginRight: "0.75rem" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.5rem"
                    }}
                  >
                    <GoDotFill
                      style={{ color: "#F47216", marginTop: "0.125rem" }}
                    />
                    <p>
                      Referral under Subhadra |<span> (01, July 2024)</span>
                    </p>
                  </div>
                </div>
              </div>
            </marquee>
          </div>
        </section>
        <div className={classes.contentMain}>
          <Grid container className={classes.contentBody} spacing={5}>
            {/* <Grid item xs={6}>
              <AccountCard
                title={"Payouts"}
                count={countState.payouts}
                color="info"
                Icon={<FaHandshake className={classes.icon} />}
                actionText={
                  <Typography display={"flex"} gap={1} alignItems="center">
                    View Detailed <FaArrowRight />
                  </Typography>
                }
                actionCallback={(e) => {
                  navigate("payouts");
                }}
              />
            </Grid> */}
            {/* <Grid item xs={6}>
              <AccountCard
                title={"Total Coupon"}
                Icon={<FaThumbtack className={classes.icon} />}
                count={countState.coupon}
                color="warning"
                actionText={
                  <Typography display={"flex"} gap={1} alignItems="center">
                    Create Coupon <FaArrowRight />
                  </Typography>
                }
                actionCallback={() => {
                  navigate("coupons");
                }}
              />
            </Grid> */}
            <Grid item xs={6}>
              <DashboardCard
                title={"Total Applicant"}
                Icon={<FaUser className={classes.icon} />}
                count="100"
                color="box3"
              />
            </Grid>
            <Grid item xs={6}>
              <DashboardCard
                title={"Total  Approved"}
                Icon={<FaCheckCircle className={classes.icon} />}
                count="68"
                color="box2"
              />
            </Grid>
            <Grid item xs={6}>
              <DashboardCard
                title={"Total Rejected"}
                Icon={<FaThumbsDown className={classes.icon} />}
                count="21"
                color="box4"
              />
            </Grid>
            <Grid item xs={6}>
              <DashboardCard
                title={"Total Pending"}
                Icon={<FaHourglassHalf className={classes.icon} />}
                count="19"
                color="box1"
              />
            </Grid>
            <Grid item xs={12}>
              <DashboardCard
                title={"Total Application"}
                Icon={<FaUser className={classes.icon} />}
                count="250"
                color="box5"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <CustomDropdownSelector date={date} setDate={setDate} />
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <RevenueChart date={date} />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
