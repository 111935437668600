import { makeStyles } from "@mui/styles";

let useStyles = makeStyles((theme) => ({
  loader: {
    height: "100vh",
    width: "100%",
    background:
      "linear-gradient(30deg, rgba(248,153,29,0.2) 0%, rgba(11,38,87,0.2) 40%, rgba(248,153,29,0.2) 100%)",

    display: "flex",
    "& span": {
      margin: "auto",
    },
  },
  headContainer: {
    height: "100vh",
    width: "100%",
  },
}));

export default useStyles;
