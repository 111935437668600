import React from "react";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router";
import layoutStyles from "../../styles/layout/sidebar.boost.style";
import { Paper } from "@mui/material";
import axios from "axios";
import endPoints from "../../config/endPoint";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import url from "../../API";
export default function LoginForm() {
  const navigate = useNavigate();
  const classes = layoutStyles();
  const [state, setState] = React.useState({
    uname: "",
    password: ""
  });
  const login = async () => {
    // if (state.uname == "" || state.password == "") {
    //   toast.error("Provide username and password", {
    //     position: "top-center"
    //   });
    // } else {
    //   try {
    //     const loginHeaders = new Headers();
    //     loginHeaders.append("Content-Type", "application/json");

    //     const data = {
    //       username: state.uname,
    //       password: state.password
    //     };
    //     const requestOptions = {
    //       method: "POST",
    //       headers: loginHeaders,
    //       body: JSON.stringify(data)
    //     };
    //     const res = await fetch(url + "admin-login/", requestOptions);
    //     const actualData = await res.json();
    //     if (actualData.admin_user_details) {
    //       // console.log(actualData.access);
    //       // console.log(actualData);
    //       localStorage.setItem("tkn", actualData.access);
    //       localStorage.setItem(
    //         "details",
    //         JSON.stringify(actualData.admin_user_details)
    //       );
    //       navigate("/dashboard");
    //     } else {
    //       toast.error("Invalid username and password", {
    //         position: "top-center"
    //       });
    //     }
    //     console.log(actualData);
    //   } catch (err) {
    //     console.log(err);
    //   }
    // }
    navigate("/dashboard");
  };
  return (
    <Paper className={classes.layOutPaper}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CustomInputField
            label="Username"
            fullWidth
            value={state.uname}
            onChange={e =>
              setState({
                ...state,
                uname: e.target.value
              })}
            // helperText="Enter your valid username"
          />
        </Grid>
        <Grid item xs={12}>
          <CustomInputField
            label="Password"
            type="password"
            fullWidth
            value={state.password}
            onChange={e =>
              setState({
                ...state,
                password: e.target.value
              })}
          />
        </Grid>

        <Grid item xs={12}>
          <CustomButton
            text={"Sign In"}
            fullWidth
            variant="contained"
            onClick={login}
          />
        </Grid>
        <ToastContainer />
      </Grid>
    </Paper>
  );
}
