import { Avatar, Divider, Typography, Button, Grid } from "@mui/material";
import React from "react";
import { FaEdit, FaTimes } from "react-icons/fa";
import { MdLogout } from "react-icons/md";
import CustomInputField from "../../components/CustomInputField/Index";
import DashboardLayout from "../../layouts";
import countryListStyles from "../../styles/pages/CountryList/index.style";
import { useNavigate } from "react-router";
import PageTable from "../../components/PageTable/Index";
import MenuButton from "../../components/MenuButton/Index";
import url from "../../API";
import { TbPasswordFingerprint } from "react-icons/tb";
import { FaEye } from "react-icons/fa";
import CustomButton from "../../components/CustomButton/Index";

export default function InfoListPage() {
  const classes = countryListStyles();
  const [list, setList] = React.useState([
    {
      id: 1,
      name: "Pravita Parida",
      district: "Kathmandu"
    },
    {
      id: 2,
      name: "Trupti Sahu",
      district: "Bhabanipatna"
    },
    {
      id: 3,
      name: "Praveen Parida",
      district: "Baramhpur"
    },
    {
      id: 4,
      name: "Dasarathi Samal",
      district: "Khorda"
    }
  ]);
  const navigate = useNavigate();
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = React.useState("");
  const [totalPages, setTotalPages] = React.useState(null);
  const [recordsTotal, setTotalrecord] = React.useState(null);

  const handleTable = async newPage => {
    // Update the page state first
    setPage(newPage);
  };

  // const table = async pg => {
  //   try {
  //     const loginHeaders = new Headers();
  //     loginHeaders.append("Content-Type", "application/json");

  //     const authToken = localStorage.getItem("tkn");
  //     if (authToken) {
  //       loginHeaders.append("Authorization", `Bearer ${authToken}`);
  //     }

  //     const requestOptions = {
  //       method: "POST",
  //       headers: loginHeaders,
  //       body: JSON.stringify({ page: pg })
  //     };
  //     const res = await fetch(
  //       url + "visainformation/getallvisainformations",
  //       requestOptions
  //     );
  //     const actualData = await res.json();
  //     setList(actualData.Visa_Information);
  //     // setList([...list, actualData.Visa_Information]);
  //     // setTotalPages(actualData.total_pages);
  //     setTotalrecord(actualData.recordsTotal);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  // console.log(list);
  // React.useEffect(
  //   () => {
  //     table(page);
  //   },
  //   [page]
  // ); // Fetch data whenever the page changes

  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
          }}
        >
          <div>
            <Typography variant="h3" color={"primary"}>
              District Authority List
            </Typography>
          </div>

          <div
            onClick={() => {
              localStorage.clear();
              navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            <Typography
              variant="h4"
              color="primary"
              style={{ display: "flex", alignItems: "center" }}
            >
              Log out
              <MdLogout size={30} style={{ marginLeft: "8px" }} />
            </Typography>
          </div>
        </div>
        <Grid container spacing={4} className={classes.contentMain}>
          <Grid item xs={3}>
            <Button
              variant="contained"
              fullWidth
              onClick={() => {
                navigate("/Create_District_Autority_Account");
              }}
            >
              Create New Account
            </Button>
          </Grid>
        </Grid>

        <Divider className={classes.divider} />
        <div className={classes.contentMain}>
          <CustomInputField
            label="Search..."
            fullWidth
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
            className="searchField"
          />
          <PageTable
            fullWidth
            cols={[
              "Sl No.",
              "District Collector Name",
              "District Name",
              "More"
            ]}
            rows={[
              ...list
                // .sort()
                // .filter((l) => {
                //   const visa_name = l.visa_name?.toLowerCase() || "";
                //   const pricing = l.visa_pricing?.toLowerCase() || "";
                //   const receive_days = l.receive_days?.toLowerCase() || "";
                //   const description =
                //     JSON.parse(
                //       l.flight_change_over_descriptions
                //     )?.blocks[0].text?.toLowerCase() || "";
                //   const validity_period =
                //     l.validity_period?.toLowerCase() || "";
                //   const searchLower = searchText.toLowerCase();
                //   return (
                //     visa_name.includes(searchLower) ||
                //     description.includes(searchLower) ||
                //     pricing.includes(searchLower) ||
                //     receive_days.includes(searchLower) ||
                //     validity_period.includes(searchText) ||
                //     searchLower === undefined
                //   );
                // })
                .map((vs, idx) => [
                  (page - 1) * 8 + (idx + 1),
                  vs.name,
                  vs.district,
                  // <span className={classes.actionBtn}>
                  //   <CustomButton
                  //     variant="contained"
                  //     color="error"
                  //     icon={<FaEye />}
                  //   />
                  //   <CustomButton
                  //     variant="contained"
                  //     color="warning"
                  //     icon={<TbPasswordFingerprint />}
                  //   />
                  // </span>
                  // <Avatar
                  //   variant="rounded"
                  //   alt="VISA"
                  //   src={vs.visa_thumbnail}
                  //   style={{ margin: "auto" }}
                  // />,
                  // vs.visa_pricing,
                  // vs.created_date.slice(0, 10),
                  // vs.receive_days,
                  // JSON.parse(vs.flight_change_over_descriptions).blocks[0].text
                  //   .length > 34
                  //   ? JSON.parse(
                  //       vs.flight_change_over_descriptions
                  //     ).blocks[0].text.slice(0, 34) + "...."
                  //   : JSON.parse(vs.flight_change_over_descriptions).blocks[0]
                  //       .text,
                  // vs.validity_period,
                  <span className={classes.actionBtn}>
                    <MenuButton />
                  </span>
                ])
            ]}
            page={page}
            setPage={handleTable} // Pass the handleTable function directly
            totalPages={totalPages}
            // table={table}
            totalrecords={recordsTotal}
          />
        </div>
      </div>
    </div>
  );
}
