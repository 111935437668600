import { Avatar, Divider, Typography, Grid, Button } from "@mui/material";
import React from "react";
import { FaEdit, FaEye, FaTimes, FaUserFriends } from "react-icons/fa";
import { BsListCheck } from "react-icons/bs";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomTable from "../../components/CustomTable/Index";
import DashboardLayout from "../../layouts";
import countryListStyles from "../../styles/pages/CountryList/index.style";
import endPoints from "../../config/endPoint";
import axios from "axios";
import { useNavigate } from "react-router";
import CountryTable from "../../components/CountryTable/Index";
import { EditorState, convertFromRaw } from "draft-js";
import { MdLogout } from "react-icons/md";
import url from "../../API";
import MenuButton from "../../components/MenuButton/Index";
export default function Customer_testimonialList() {
  const classes = countryListStyles();

  const [list, setList] = React.useState([
    {
      id: 1,
      name: "Pravita Parida",
      district: "Kathmandu"
    },
    {
      id: 2,
      name: "Trupti Sahu",
      district: "Bhabanipatna"
    },
    {
      id: 3,
      name: "Praveen Parida",
      district: "Baramhpur"
    },
    {
      id: 4,
      name: "Dasarathi Samal",
      district: "Khorda"
    }
  ]);
  const [visalist, setVisaList] = React.useState([]);
  const [mainlist, setMainList] = React.useState([]);
  const [countrylist, setcountrylist] = React.useState([]);
  const [testimonialMap, settestimonialMap] = React.useState([]);
  const navigate = useNavigate();

  const token = localStorage.getItem("tkn");
  const [searchText, setSearchText] = React.useState("");
  const table = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders
      };
      const res = await fetch(
        url + "customertestimonial/getallcustomertestimonial",
        requestOptions
      );
      const actualData = await res.json();
      const data = actualData.Customer_Testimonials.filter(
        review => review.visa_id === null
      );
      console.log(data);
      setList(data);
    } catch (err) {
      console.log(err);
    }
  };

  //   async function country_info() {
  //     try {
  //       const loginHeaders = new Headers();
  //       loginHeaders.append("Content-Type", "application/json");

  //       // Assuming you have an authorization token stored in localStora
  //       const authToken = localStorage.getItem("tkn");
  //       if (authToken) {
  //         loginHeaders.append("Authorization", `Bearer ${authToken}`);
  //       }

  //       const requestOptions = {
  //         method: "GET",
  //         headers: loginHeaders,
  //       };
  //       const res = await fetch(
  //         url +"country/getallcountry",
  //         requestOptions
  //       );
  //       const response = await res.json();
  //       setcountrylist(response.Country);
  //       console.log(response.Country);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   }
  //   async function testimonial_map() {
  //     try {
  //       const loginHeaders = new Headers();
  //       loginHeaders.append("Content-Type", "application/json");

  //       // Assuming you have an authorization token stored in localStorage
  //       const authToken = localStorage.getItem("tkn");
  //       if (authToken) {
  //         loginHeaders.append("Authorization", `Bearer ${authToken}`);
  //       }

  //       const requestOptions = {
  //         method: "GET",
  //         headers: loginHeaders,
  //       };
  //       const res = await fetch(
  //         url +"customertestimonialmap/getallcustomertestimonialmap",
  //         requestOptions
  //       );
  //       const response = await res.json();
  //       settestimonialMap(response.FAQ_For_Customer);
  //       console.log(response.FAQ_For_Customer);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   }
  //   const getVisaInfoWithCountry = () => {
  //     return testimonialMap
  //       .map((ts) => {
  //         const visaInfo = list.find((info) => info.id === ts.testimonial_id);

  //         const countryfrom = countrylist.find(
  //           (ct) => ct.id === ts.from_country_id
  //         );
  //         const countryto = countrylist.find((ct) => ct.id === ts.to_country_id);
  //         if (visaInfo && countryfrom && countryto) {
  //           return {
  //             visaInfo,
  //             from_country_id: ts.from_country_id,
  //             to_country_id: ts.to_country_id,
  //             from_country: countryfrom.country_name,
  //             to_country: countryto.country_name,
  //             map_id: ts.id,
  //             testimonial_id: ts.testimonial_id,
  //           };
  //         }

  //         return null; // or any other value you prefer for undefined cases
  //       })
  //       .filter(Boolean); // filter out undefined values from the array
  //   };

  // React.useEffect(() => {
  //   table();
  // }, []);
  //   React.useEffect(() => {
  //     const visaInfoWithTypeArray = getVisaInfoWithCountry();
  //     setMainList(visaInfoWithTypeArray);
  //     console.log("combined array");
  //     console.log(visaInfoWithTypeArray);
  //   }, [list, testimonialMap, countrylist]);
  const handleDelete = async (idx, map) => {
    console.log(map);
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = { id: idx };
      const requestOptions = {
        method: "POST",
        headers: loginHeaders,
        body: JSON.stringify(data)
      };
      const res = await fetch(
        url + "customertestimonial/deletecustomertestimonial",
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      // setVi saList(actualData.Country);
      if (actualData.status == 200) {
        table();
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
          }}
        >
          <div>
            <Typography variant="h3" color={"primary"}>
              Block Authority List
            </Typography>
          </div>
          <div
            onClick={() => {
              localStorage.clear();
              navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            {" "}<Typography
              variant="h4"
              color="primary"
              style={{ display: "flex", alignItems: "center" }}
            >
              Log out
              <MdLogout size={30} style={{ marginLeft: "8px" }} />
            </Typography>
          </div>
        </div>
        <Grid container spacing={4} className={classes.contentMain}>
          <Grid item xs={3}>
            <Button
              variant="contained"
              fullWidth
              onClick={e => {
                navigate("/Create_Block_Autority_Account", {
                  state: { customer_data: null }
                });
              }}
            >
              Create New Account
            </Button>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <div className={classes.contentMain}>
          <CustomInputField
            label="Search..."
            fullWidth
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
            className="searchField"
          />
          <CountryTable
            fullWidth
            cols={[
              "Sl No.",
              "BDO Name",
              "Block Name",
              "More"
              // "Customer Name",
              // "Customer Image",
              // "Description",
              // "Title",
              // "Location",
              // "Date",
              // "Action",
            ]}
            rows={[
              ...list
                // .filter((l) => {
                //   const customer_name = l.customer_name?.toLowerCase() || "";
                //   const title = l.title?.toLowerCase() || "";
                //   const customer_location =
                //     l.customer_location?.toLowerCase() || "";
                //   const description =
                //     JSON.parse(l.description)?.blocks[0].text?.toLowerCase() ||
                //     "";
                //   const date = l.date?.toLowerCase() || "";
                //   const searchLower = searchText.toLowerCase();
                //   return (
                //     customer_name.includes(searchLower) ||
                //     title.includes(searchLower) ||
                //     customer_location.includes(searchLower) ||
                //     description.includes(searchLower) ||
                //     date.includes(searchText) ||
                //     searchLower === undefined
                //   );
                // })
                .map((vs, idx) => [
                  idx + 1,
                  vs.name,
                  vs.district,
                  <span className={classes.actionBtn}>
                    <MenuButton />
                  </span>
                  // vs.customer_name,
                  // <Avatar
                  //   variant="rounded"
                  //   alt="VISA"
                  //   src={vs.customer_image}
                  //   style={{
                  //     margin: "auto",
                  //   }}
                  // />,
                  // JSON.parse(vs.description).blocks[0].text.length > 25
                  //   ? JSON.parse(vs.description).blocks[0].text.slice(0, 25) +
                  //     "...."
                  //   : JSON.parse(vs.description).blocks[0].text,
                  // vs.title,
                  // vs.customer_location,
                  // vs.date,

                  // <span className={classes.actionBtn}>
                  //   <CustomButton
                  //     variant="contained"
                  //     color="error"
                  //     onClick={e => handleDelete(vs.id, vs.map_id)}
                  //     icon={<FaTimes />}
                  //   />
                  //   <CustomButton
                  //     variant="contained"
                  //     color="warning"
                  //     onClick={e => {
                  //       // localStorage.setItem(
                  //       //   "updateTestimonial",
                  //       //   JSON.stringify(vs)
                  //       // );
                  //       // localStorage.setItem(
                  //       //   "updateTestMap",
                  //       //   JSON.stringify(vs)
                  //       // );
                  //       // localStorage.setItem("testimonialMapId", vs.map_id);
                  //       // localStorage.setItem("entry_id", vs.entry_id);
                  //       navigate("/customer_testimonial", {
                  //         state: { updateId: vs }
                  //       });
                  //     }}
                  //     icon={<FaEdit />}
                  //   />
                  // </span>
                ])
            ]}
          />
        </div>
      </div>
    </div>
  );
}
