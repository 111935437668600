import { Avatar, Divider, Grid, Typography, Button } from "@mui/material";
import React, { useState } from "react";
import { BsFillPatchCheckFill } from "react-icons/bs";
import { FaUserFriends } from "react-icons/fa";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomInputAndSelectField from "../../components/CustomInputAndSelectField/Index";
import CustomPill from "../../components/CustomPill/Index";
import CustomTable from "../../components/CustomTable/Index";
import DashboardLayout from "../../layouts";
import offersStyles from "../../styles/pages/Offers/index.style";
import axios from "axios";
import endPoints from "../../config/endPoint";
import { Editor } from "react-draft-wysiwyg";
import CustomDatePicker from "../../components/CustomDateField/Index";
import { convertFromRaw, EditorState, convertToRaw } from "draft-js";
import { storage } from "../../firebase_config";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router";
import { MdLogout } from "react-icons/md";
import { useLocation } from "react-router-dom";
import url from "../../API";
export default function Customer_testimonial() {
  const navigate = useNavigate();
  const classes = offersStyles();
  const token = localStorage.getItem("tkn");

  const location = useLocation();
  const { updateId } = location.state || {};
  const [list, setList] = React.useState([]);
  const [visalist, setVisalist] = React.useState([]);
  const [state, setState] = React.useState({
    name: "",
    image: "",
    description: EditorState.createEmpty(),
    date: "",
    location: "",
    title: "",
    rating: ""
  });
  React.useEffect(() => {
    if (updateId) {
      setState({
        name: updateId.customer_name,
        image: updateId.customer_image,
        description: EditorState.createWithContent(
          convertFromRaw(JSON.parse(updateId.description))
        ),
        date: updateId.date,
        location: updateId.customer_location,
        title: updateId.title,
        rating: updateId.rating,
        isUpdate: true,
        id: updateId.id
      });
    }
  }, []);

  const handleSaveOfficer = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = {
        customer_name: state.name,
        customer_image: state.image,
        description: JSON.stringify(
          convertToRaw(state.description.getCurrentContent())
        ),
        customer_location: state.location,
        title: state.title,
        date: state.date,
        rating: state.rating
      };
      const requestOptions = {
        method: "POST",
        headers: loginHeaders,
        body: JSON.stringify(data)
      };
      const res = await fetch(
        url + "customertestimonial/createcustomertestimonial",
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      // setVisaList(actualData.Country);
      if (actualData.status == 200) {
        // table();
        // axios
        //   .post(
        //     url +"customertestimonialmap/createcustomertestimonialmap",
        //     {
        //       from_country_id: state.from_id,
        //       to_country_id: state.to_id,
        //       testimonial_id: actualData.new_customer_testimonial_id,
        //     },
        //     {
        //       headers: {
        //         /* Your headers here */
        //         "Content-Type": "application/json", // Example header
        //         Authorization: `Bearer ${token}`, // Example authorization header
        //       },
        //     }
        //   )
        //   .then((r) => {
        //     setState({
        //       name: "",
        //       image: "",
        //       description: EditorState.createEmpty(),
        //       date: "",
        //       location: "",
        //       title: "",
        //       from_country: "",
        //       from_id: "",
        //       to_id: "",
        //       to_country: "",
        //       visa_id: "",
        //       visa_name: "",
        //       rating: "",
        //     });
        //     localStorage.removeItem("customer");
        //     navigate("/testimonial_list");
        //   })
        //   .catch((error) => {
        //     console.error("Error fetching data:", error);
        //     // Handle error here
        //   });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleUpdateOffice = async id => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = {
        customer_name: state.name,
        customer_image: state.image,
        description: JSON.stringify(
          convertToRaw(state.description.getCurrentContent())
        ),
        customer_location: state.location,
        title: state.title,
        date: state.date,
        id: id,
        rating: state.rating
      };
      const requestOptions = {
        method: "POST",
        headers: loginHeaders,
        body: JSON.stringify(data)
      };
      const res = await fetch(
        url + "customertestimonial/updatecustomertestimonial",
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      // setVisaList(actualData.Country);
      if (actualData.status == 200) {
        navigate("/Block_Autority_List");
        // table();
        //     axios
        //       .post(
        //         url +"customertestimonialmap/updatecustomertestimonialmap",
        //         {
        //           from_country_id: state.from_id,
        //           to_country_id: state.to_id,
        //           id: id,
        //           FAQ_id: state.map_id,
        //           //description pass the text only
        //           //remove the thumbnail image
        //           //json pasrse string to send description
        //           // visa_time: state.visa_time,
        //         },
        //         {
        //           headers: {
        //             /* Your headers here */
        //             "Content-Type": "application/json", // Example header
        //             Authorization: `Bearer ${token}`, // Example authorization header
        //           },
        //         }
        //       )
        //       .then((r) => {
        //         localStorage.removeItem("updateTestimonial");
        //         localStorage.removeItem("updateTestMap");
        //         localStorage.removeItem("customer");
        //         setState({
        //           name: "",
        //           image: "",
        //           description: EditorState.createEmpty(),
        //           date: "",
        //           location: "",
        //           title: "",
        //           from_country: "",
        //           from_id: "",
        //           to_id: "",
        //           to_country: "",
        //           isUpdate: false,
        //           visa_id: "",
        //           visa_name: "",
        //           map_id: "",
        //           rating: "",
        //         });
        //         navigate("/testimonial_list");
        //       })
        //       .catch((error) => {
        //         console.error("Error fetching data:", error);
        //         // Handle error here
        //       });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const [formData, setFormData] = useState({
    district: "",
    email: "",
    username: "",
    password: "",
    confirmPassword: ""
  });

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    // Handle form submission here
    if (formData.password === formData.confirmPassword) {
      console.log("Form submitted successfully", formData);
    } else {
      console.log("Passwords do not match");
    }
  };

  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
          }}
        >
          <div>
            <Typography variant="h3" color={"primary"}>
              Customer Testimonial
            </Typography>
          </div>
          <div
            onClick={() => {
              localStorage.clear();
              navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            {" "}<Typography
              variant="h4"
              color="primary"
              style={{ display: "flex", alignItems: "center" }}
            >
              Log out
              <MdLogout size={30} style={{ marginLeft: "8px" }} />
            </Typography>
          </div>
        </div>
        <Divider className={classes.divider} />
        {/* <div className={classes.contentMain}>
          <Grid container spacing={3} mb={3}>
            <Grid item xs={7}>
              <CustomInputField
                fullWidth
                label="Customer Name"
                value={state.name}
                onChange={(e) => {
                  setState({
                    ...state,
                    name: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={7}>
              <CustomInputField
                type="date"
                label="Date"
                fullWidth
                value={state.date}
                onChange={(e) =>
                  setState({
                    ...state,
                    date: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={7}>
              <CustomInputField
                fullWidth
                label=" Testimonial Title"
                value={state.title}
                onChange={(e) => {
                  setState({
                    ...state,
                    title: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={7}>
              <CustomInputField
                fullWidth
                label="Location"
                value={state.location}
                onChange={(e) => {
                  setState({
                    ...state,
                    location: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={7}>
              <CustomInputField
                fullWidth
                label="Customer Photo"
                type="file"
                onChange={(e) => {
                  const imageRef = ref(storage, `/customers+${Math.random()}`);
                  uploadBytes(imageRef, e.target.files[0]).then(() => {
                    getDownloadURL(imageRef)
                      .then((url) => {
                        // link = url;
                        //console.log("Image URL:", url);

                        // localStorage.setItem("customer", url);
                        // console.log("variable value " + state.image);
                        // handleSaveVisaCategory();
                        setState({
                          ...state,
                          image: url,
                        });
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  });
                }}
              />
            </Grid>
            <Grid item xs={2}>
              {state.image && (
                <>
                  <p> image</p>
                  <Avatar
                    variant="rounded"
                    alt="VISA"
                    src={state.image}
                    style={{
                      margin: "auto",
                    }}
                  />
                </>
              )}
            </Grid>
            <Grid item xs={7}>
              <CustomInputField
                fullWidth
                label="Rating"
                value={state.rating}
                onChange={(e) => {
                  setState({
                    ...state,
                    rating: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={12}>
              Description
              <Editor
                label="Description"
                editorState={state.description}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                // value={state.country_description}
                onEditorStateChange={(e) =>
                  setState({
                    ...state,
                    description: e,
                  })
                }
              />
            </Grid>
            <Grid item xs={12}>
              {state.isUpdate ? (
                <Button
                  variant="contained"
                  fullWidth
                  color="warning"
                  onClick={(e) => {
                    handleUpdateOffice(state.id);
                  }}
                >
                  Update
                </Button>
              ) : (
                <Button
                  variant="contained"
                  fullWidth
                  onClick={(e) => {
                    handleSaveOfficer();
                  }}
                >
                  Create
                </Button>
              )}
            </Grid>
          </Grid>
        </div> */}
        {/* <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px"
          }}
        >
          <div
            style={{
              border: `1px solid #E0E0E0`,
              borderRadius: "40px",
              padding: "10px",
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"
            }}
          > */}
        <div style={styles.container}>
          {/* <h2 style={styles.header}>Sign Up</h2> */}
          <form onSubmit={handleSubmit} style={styles.form}>
            <label style={styles.label}>
              Select District:
              <select
                name="district"
                value={formData.district}
                onChange={handleChange}
                style={styles.input}
              >
                <option value="">Select District</option>
                <option value="Bhubaneswar">Bhubaneswar</option>
                <option value="Cuttack">Cuttack</option>
                <option value="Puri">Puri</option>
                <option value="Rourkela">Rourkela</option>
                {/* Add more districts as needed */}
              </select>
            </label>
            <label style={styles.label}>
              Email:
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                style={styles.input}
              />
            </label>
            <label style={styles.label}>
              Username:
              <input
                type="text"
                name="username"
                value={formData.username}
                onChange={handleChange}
                style={styles.input}
              />
            </label>
            <label style={styles.label}>
              Password:
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                style={styles.input}
              />
            </label>
            <label style={styles.label}>
              Confirm Password:
              <input
                type="password"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                style={styles.input}
              />
            </label>
            <button type="submit" style={styles.button}>
              Submit
            </button>
          </form>
        </div>
        {/* </div>
        </div> */}
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    backgroundColor: "#fff"
  },
  header: {
    fontSize: "24px",
    marginBottom: "20px"
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "400px",
    padding: "20px",
    borderRadius: "5px",
    boxShadow: "0 0 10px rgba(0,0,0,0.1)",
    backgroundColor: "#fff"
  },
  label: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "15px",
    width: "100%"
  },
  input: {
    padding: "10px",
    marginTop: "5px",
    borderRadius: "3px",
    border: "1px solid #ccc"
  },
  button: {
    padding: "10px 20px",
    borderRadius: "5px",
    border: "none",
    backgroundColor: "#000",
    color: "#fff",
    cursor: "pointer"
  }
};
