import { Button } from "@mui/material";
import React from "react";
import customButtonStyles from "../../styles/components/CustomButton/index.styles";

export default function CustomButton({ icon, text, actionCallBack, ...props }) {
  const classes = customButtonStyles();
  return (
    <Button onClick={actionCallBack} className={classes.button} {...props}>
      {icon}
      {text}
    </Button>
  );
}
