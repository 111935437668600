import { Avatar, Divider, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { FaEdit, FaEye, FaTimes, FaUserFriends } from "react-icons/fa";
import { RiVisaFill } from "react-icons/ri";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomPill from "../../components/CustomPill/Index";
import CustomTable from "../../components/CustomTable/Index";
import endPoints from "../../config/endPoint";
import DashboardLayout from "../../layouts";
import offerStyles from "../../styles/pages/VisaCategory/index.style";
import axios from "axios";
import CustomTimeTable from "../../components/CustomTimeTable/Index";
import CustomInputAndSelectField from "../../components/CustomInputAndSelectField/Index";
import { storage } from "../../firebase_config";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import url from "../../API";
import { MdLogout } from "react-icons/md";
export default function Partener_toWork() {
  const classes = offerStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { visaId } = location.state || {};
  const token = localStorage.getItem("tkn");
  const [logoPhotos, setlogoPhotos] = React.useState([]);
  const [logoPrevPics, setlogoPics] = React.useState([]);
  const formData = new FormData();
  const [state, setState] = React.useState({
    visa_name: "",
    searchText: "",
    logo: [],
    visa_id: "",
    isUpdate: false,
  });
  const [visaList, setVisaList] = React.useState([]);
  const [partnerList, setPartnerList] = React.useState([]);
  const [mainlist, setMainList] = React.useState([]);
  async function table() {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = { visa_id: visaId };
      const requestOptions = {
        method: "POST",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        url + "visa/partnerweworkwithdetailsbyvisaid",
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      // setVisaList(actualData.Partners_Details);
      const logoUrls = actualData.Partners_Details.map((item) => item.logo);
      //  setlogoPics(logoUrls);
      setlogoPics(actualData.Partners_Details);
      setlogoPhotos(logoUrls);
      setState({
        ...state,
        visa_name: actualData.Visa_Name,
        visa_id: visaId,
      });
      // const pics = pl.logos.map((item) => item.logo);
      // setlogoPics(pl.logos);
      // setlogoPhotos(pics);
      // setState({
      //   visa_id: pl.visa_id,
      //   visa_name: pl.visa_name,
      //   // logo: pl.plInfo.logos,
      //   isUpdate: true,
      //   id: pl.id,
      // });
    } catch (e) {
      console.log(e);
    }
  }
  async function table2() {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        url + "partnerweworkwith/getallpartnerweworkwith",
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      setPartnerList(actualData.Partners_We_Work_With);
    } catch (e) {
      console.log(e);
    }
  }
  const getVisaName = () => {
    return visaList
      .map((vs) => {
        const plInfo = partnerList.find((pl) => pl.visa_id == vs.id);

        if (plInfo) {
          return {
            plInfo,
            visa_name: vs.visa_name,
          };
        }
        return null; // or any other value you prefer for undefined cases
      })
      .filter(Boolean); // filter out undefined values from the array
  };
  const removePhotos = (index, chk) => {
    if (chk == "prev") {
      const newImages = [...logoPhotos];
      newImages.splice(index, 1);
      localStorage.removeItem(`image${index + 1}`);
      setlogoPhotos(newImages);
    } else {
      const newImages = [...state.logo];
      newImages.splice(index, 1);
      localStorage.removeItem(`image${index + 1}`);
      setState({ ...state, logo: newImages });
    }
  };
  React.useEffect(() => {
    table();
  }, []);

  const handleSaveVisaCategory = () => {
    axios
      .post(
        url + "partnerweworkwith/createpartnerweworkwith",
        {
          visa_id: state.visa_id,
          logo: state.logo,
        },
        {
          headers: {
            /* Your headers here */
            "Content-Type": "application/json", // Example header
            Authorization: `Bearer ${token}`, // Example authorization header
          },
        }
      )
      .then((r) => {
        table2();
        localStorage.removeItem("logo");
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        // Handle error here
      });
    setState({
      visa_name: "",
      logo: [],
      visa_id: "",
      isUpdate: false,
    });
  };
  const handleVisaCategoryDelete = (idx) => {
    axios
      .post(
        url + "partnerweworkwith/deletepartnerweworkwith",
        {
          id: idx,
        },
        {
          headers: {
            /* Your headers here */
            "Content-Type": "application/json", // Example header
            Authorization: `Bearer ${token}`, // Example authorization header
          },
        }
      )
      .then((r) => table())
      .catch((error) => {
        console.error("Error fetching data:", error);
        // Handle error here
      });
  };
  const handleUpdateVisaCategory = (idx) => {
    var visapics = logoPhotos.concat(state.logo);
    visapics = visapics.filter((item) => item !== null && item !== undefined);
    console.log(visapics);
    axios
      .post(
        url + "partnerweworkwith/updatepartnerweworkwith",
        {
          id: idx,
          visa_id: visaId,
          logo: visapics,
        },
        {
          headers: {
            /* Your headers here */
            "Content-Type": "application/json", // Example header
            Authorization: `Bearer ${token}`, // Example authorization header
          },
        }
      )
      .then((r) => {
        table();
        localStorage.getItem("logo")
          ? localStorage.removeItem("logo")
          : console.log("done");
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        // Handle error here
      });
    setState({
      visa_name: "",
      logo: [],
      visa_id: "",
      isUpdate: false,
    });
  };
  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography variant="h3" color={"primary"}>
              Partners we with work with
            </Typography>
          </div>
          <div
            onClick={() => {
              localStorage.clear();
              navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            {" "}
            <Typography
              variant="h4"
              color="primary"
              style={{ display: "flex", alignItems: "center" }}
            >
              Log out
              <MdLogout size={30} style={{ marginLeft: "8px" }} />
            </Typography>
          </div>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.contentMain}>
          <p className={classes.title}>Partners Information</p>
          <Paper className={classes.paper}>
            <Grid container spacing={3} className="content" alignItems={"end"}>
              <Grid item xs={5}>
                <p>LOGO</p>
                <input
                  style={{
                    padding: "10px",
                    borderColor: "rgba(148,158,171,0.32)",
                    boxShadow: "1px 3px 10px rgba(0,0,0,0.17)",
                    width: "100%",
                    borderRadius: "12px",
                  }}
                  type="file"
                  multiple
                  fullWidth
                  label="logo Photos"
                  onChange={(e) => {
                    const files = Array.from(e.target.files);
                    console.log("Files array:", files); // Check if files are converted to an array
                    files.map((el, index) => {
                      var imageRef = ref(storage, `/logo+${Math.random()}`);
                      uploadBytes(imageRef, el).then(() => {
                        getDownloadURL(imageRef)
                          .then((url) => {
                            //console.log("Image URL:", url);
                            localStorage.setItem(`logo${index + 1}`, url);

                            set_img();
                          })
                          .catch((error) => {
                            //console.log(error);
                          });
                      });
                      function set_img() {
                        const data = [];
                        for (let i = 1; i <= files.length; i++) {
                          if (localStorage.getItem(`logo${i}`)) {
                            data.push(localStorage.getItem(`logo${i}`));
                          }
                        }
                        console.log(data);

                        setState({
                          ...state,
                          logo: data,
                        });
                      }
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}></Grid>
              <Grid item xs={12} style={{ display: "flex", gap: "20px" }}>
                {logoPhotos != null
                  ? logoPhotos.map((el, idx) => (
                      <>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <img src={el} style={{ height: "50px" }} />
                          <CustomButton
                            variant="contained"
                            color="error"
                            onClick={() => removePhotos(idx, "prev")}
                            icon={<FaTimes />}
                          />
                        </div>
                      </>
                    ))
                  : console.log(logoPhotos)}

                {state.logo != null
                  ? state.logo.map((el, idx) => (
                      <>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <img src={el} style={{ height: "50px" }} />
                          <CustomButton
                            variant="contained"
                            color="error"
                            onClick={() => removePhotos(idx, "new")}
                            icon={<FaTimes />}
                          />
                        </div>
                      </>
                    ))
                  : console.log(state.logo)}
              </Grid>
              <Grid item xs={5}>
                <CustomInputAndSelectField
                  options={visaList.map((item) => item.visa_name)} // Set options from entry type names
                  value={state.visa_name}
                  label="Visa names"
                  fullWidth
                  changeCallBack={(e, v) => {
                    const selectedEntryTypeName = v;

                    const selectedEntryType = visaList.find(
                      (item) => item.visa_name === selectedEntryTypeName
                    );

                    setState((prevState) => ({
                      ...prevState,
                      visa_name: v,
                      visa_id: selectedEntryType ? selectedEntryType.id : "",
                    }));
                  }}
                  // Handle onChange event
                />
              </Grid>

              <Grid item xs={2} alignItems="end">
                <CustomButton
                  text={"Update"}
                  variant="contained"
                  color="warning"
                  fullWidth
                  onClick={(e) => {
                    handleUpdateVisaCategory();
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
          {/* <p className={classes.title}>Partners Lists</p>
          <Paper className={classes.paper}>
            <CustomInputField
              label="Search..."
              fullWidth
              value={state.searchText}
              onChange={(e) =>
                setState({
                  ...state,
                  searchText: e.target.value,
                })
              }
              className="searchField"
            />
            <CustomTimeTable
              fullWidth
              cols={["Sl No.", "Visa", "Logo", "Action"]}
              rows={[
                ...partnerList
                  .filter(
                    (l) =>
                      l.visa_name
                        .toLowerCase()
                        .includes(state.searchText.toLowerCase()) ||
                      state.searchText.toLowerCase() === undefined
                  )
                  .map((pl, idx) => [
                    idx + 1,
                    pl.visa_name,
                    <Avatar
                      variant="rounded"
                      alt="VISA"
                      src={pl.logos[0].logo}
                      style={{
                        margin: "auto",
                      }}
                    />,
                    <span className={classes.actionBtn}>
                      <CustomButton
                        variant="contained"
                        color="error"
                        onClick={(e) => handleVisaCategoryDelete(pl.id)}
                        icon={<FaTimes />}
                      />
                      <CustomButton
                        variant="contained"
                        color="warning"
                        onClick={(e) => {
                          const pics = pl.logos.map((item) => item.logo);
                          setlogoPics(pl.logos);
                          setlogoPhotos(pics);
                          setState({
                            visa_id: pl.visa_id,
                            visa_name: pl.visa_name,
                            // logo: pl.plInfo.logos,
                            isUpdate: true,
                            id: pl.id,
                          });
                        }}
                        icon={<FaEdit />}
                      />
                    </span>,
                  ]),
              ]}
            />
          </Paper> */}
        </div>
      </div>
    </div>
  );
}
