// ----------------------------------------------------------------------

const shape = {
  borderRadius: 10,
  borderRadiusSm: 6,
  borderRadiusMd: 12,
  borderRadiusLg: 16,
};

export default shape;
