import { Card, Typography } from "@mui/material";
// material
import { alpha, styled } from "@mui/material/styles";
import React from "react";
import CustomButton from "../../../components/CustomButton/Index";
const RootStyle = styled(Card)(({ theme, bg, tColor }) => ({
  boxShadow: "none",
  position: "relative",
  padding: theme.spacing(5, 0),
  color: theme.palette[tColor].darker,
  backgroundColor: theme.palette[tColor].lighter,

  backgroundImage: `linear-gradient(135deg, ${alpha(
    theme.palette[tColor].dark,
    0
  )} 0%, ${alpha(theme.palette[tColor].dark, 0.24)} 100%)`,
}));

const IconWrapperStyle = styled("div")(({ theme, tColor }) => ({
  color: theme.palette[tColor].dark,
}));

// ----------------------------------------------------------------------

export default function AccountCard({
  title,
  Icon,
  count,
  color,
  actionCallback,
  actionText,
  form,
}) {
  return (
    <RootStyle tColor={color}>
      <Typography
        variant="h3"
        style={{
          display: "flex",
          alignItems: "center",
          gap: 5,
          padding: 20,
        }}
      >
        {Icon}
        {count}
      </Typography>
      {form}
      <Typography
        variant="h5"
        sx={{
          opacity: 0.72,
          position: "absolute",
          top: 10,
          left: 20,
          fontWeight: "bold",
        }}
      >
        {title}
      </Typography>
      {!!actionText && (
        <CustomButton
          variant="contained"
          color={color}
          text={actionText}
          onClick={actionCallback}
          style={{
            position: "absolute",
            bottom: 20,
            right: 20,
          }}
        />
      )}
    </RootStyle>
  );
}
