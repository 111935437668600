import { Avatar, Divider, Grid, Paper, Typography } from "@mui/material";
import React, { useState, useRef } from "react";
import { FaEdit, FaEye, FaTimes, FaUserFriends } from "react-icons/fa";
import { RiVisaFill } from "react-icons/ri";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomInputAndSelectField from "../../components/CustomInputAndSelectField/Index";

import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";

import FormGroup from "@mui/material/FormGroup";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import CustomPill from "../../components/CustomPill/Index";
import CustomTable from "../../components/CustomTable/Index";
import endPoints from "../../config/endPoint";
import DashboardLayout from "../../layouts";
import offerStyles from "../../styles/pages/VisaCategory/index.style";
import axios from "axios";
import CustomTimeTable from "../../components/CustomTimeTable/Index";
import CountryTable from "../../components/CountryTable/Index";
import slugify from "react-slugify";
import { storage } from "../../firebase_config";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { useNavigate } from "react-router";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { PDFDownloadLink, pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import Applicant from "./Applicant";
import ApplicantPDF from "./ApplicantPDF";
import { MdLogout } from "react-icons/md";
import { SettingsApplications } from "@mui/icons-material";
import url from "../../API";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { FaCheckCircle } from "react-icons/fa";
import ToggleButton from "@mui/lab/ToggleButton";
export default function Application_details() {
  const navigate = useNavigate();
  const classes = offerStyles();
  const token = localStorage.getItem("tkn");
  const [unique, setUnique] = useState(null);
  const formData = new FormData();
  const applicantRefs = useRef([]);
  const [selectedApplicant, setSelectedApplicant] = useState(null);

  const handleGeneratePDF = (index) => {
    setSelectedApplicant({ ...state.applicant_info[index], index });
  };

  const handleDownload = () => {
    setSelectedApplicant(null);
  };
  const [state, setState] = React.useState({
    visa_name: "",
    visa_id: "",
    arrival_date: "",
    applicant_info: [
      {
        photo: "",
        cause: "",
        front_passport_photo: "",
        back_passport_photo: "",
        first_name: "",
        isPopupOpen: false,
        uploadedFile: null,
        visa_status: "",
        last_name: "",
        gender: "",
        passport_number: "",
        date_of_birth: "",
        passport_issued_on: "",
        passport_valid_till: "",
        address_from_passport: "",
        visa_status: null,
        id: null,
        step: null,
        question_info: [],
      },
    ],
  });
  const [app_status, setApp_status] = React.useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  function table() {
    axios
      .get(url + "visa_applicationstatus/getallvisa_applicationstatus", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // Handle the response
        setApp_status(response.data.Visa_Application_Status);
        console.log(response.data.Visa_Application_Status);
      })
      .catch((error) => {
        // Handle errors
        console.error("Error fetching data:", error);
      });
  }
  function formatDate(dateString) {
    // Split the date string into day, month, and year parts
    const [day, month, year] = dateString.split("-");

    // Create a new Date object with the parts rearranged in yyyy-mm-dd format
    const formattedDate = new Date(`${year}-${month}-${day}`);

    // Extract the year, month, and day from the formatted date
    const formattedYear = formattedDate.getFullYear();
    const formattedMonth = String(formattedDate.getMonth() + 1).padStart(
      2,
      "0"
    ); // Months are zero-based
    const formattedDay = String(formattedDate.getDate()).padStart(2, "0");

    // Combine the formatted parts into yyyy-mm-dd format
    const result = `${formattedYear}-${formattedMonth}-${formattedDay}`;

    return result;
  }
  const getStatusColor = (status) => {
    switch (status) {
      case "Granted By Admin":
      case "Granted By Embassy":
        return "green";
      case "Pending By Admin":
      case "Pending By Embassy":
        return "orange";
      case "Rejected By Admin":
      case "Rejected By Embassy":
        return "red";
      default:
        return "black"; // default color if none match
    }
  };

  function conformatDate(dateString) {
    // Split the date string into day, month, and year
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    if (!regex.test(dateString)) {
      return "Invalid date format. Please provide date in yyyy-mm-dd format.";
    }

    // Split the input string into year, month, and day
    const [year, month, day] = dateString.split("-");

    // Create a new Date object with the input values
    const dateObject = new Date(year, month - 1, day);

    // Format the date to dd-mm-yyyy
    const formattedDate = `${dateObject.getDate()}-${
      dateObject.getMonth() + 1
    }-${dateObject.getFullYear()}`;

    return formattedDate;
  }
  const handlePopupClose = (index) => {
    const updatevar = [...state.applicant_info];
    updatevar[index].isPopupOpen = false;
    setState({
      ...state,
      applicant_info: updatevar,
    });
  };

  const handleFileChange = (index, e) => {
    const imageRef = ref(storage, `/file+${Math.random()}`);
    uploadBytes(imageRef, e.target.files[0]).then(() => {
      getDownloadURL(imageRef)
        .then((url) => {
          // localStorage.setItem("file", url);

          const updatevar = [...state.applicant_info];
          updatevar[index].uploadedFile = url;
          setState({
            ...state,
            applicant_info: updatevar,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    });
    // const updatevar = [...state.applicant_info];
    // updatevar[index].uploadedFile = e.target.files[0];
    // setState({
    //   ...state,
    //   applicant_info: updatevar,
    // });
  };

  const handleFileUpload = (index) => {
    // Add your file upload logic here, e.g., send the file to a server
    handlePopupClose(index);
  };

  React.useEffect(() => {
    table();
    if (localStorage.getItem("applicantInfo")) {
      const userdata = JSON.parse(localStorage.getItem("applicantInfo"));
      setUnique(userdata.unique_id);
      axios
        .post(
          url + "applicationdetails/applicationdetailsbyuniqueid",
          {
            unique_id: userdata.unique_id,
          },
          {
            headers: {
              /* Your headers here */
              "Content-Type": "application/json", // Example header
              Authorization: `Bearer ${token}`, // Example authorization header
            },
          }
        )
        .then((r) => {
          // Assuming applicant_data is an array of application details
          const applicant_data = r.data.application_details;
          if (applicant_data && Array.isArray(applicant_data)) {
            // Map through the applicant_data array and format each item
            const formattedApplicantInfo = applicant_data.map((applicant) => {
              // const arrival_date = formatDate(applicant.date_of_arrival);
              // const dob = applicant.date_of_birth
              //   ? formatDate(applicant.date_of_birth)
              //   : null;
              // const passport_val = applicant.passport_valid_till
              //   ? formatDate(applicant.passport_valid_till)
              //   : null;
              // const passport_issued = applicant.passport_issued_on
              //   ? formatDate(applicant.passport_issued_on)
              //   : null;
              const transformedData = applicant.questions_data.map((item) => ({
                question_id: item.question_id,

                answer: item.answer,
              }));
              console.log(transformedData);
              return {
                photo: applicant.photo,
                front_passport_photo: applicant.front_passport_photo,
                back_passport_photo: applicant.back_passport_photo,
                first_name: applicant.first_name,
                last_name: applicant.last_name,
                gender: applicant.gender,
                passport_number: applicant.passport_number,
                date_of_birth: applicant.date_of_birth,
                passport_issued_on: applicant.passport_issued_on,
                passport_valid_till: applicant.passport_valid_till,
                address_from_passport: applicant.address_from_passport,
                visa_status: applicant.visa_status,
                id: applicant.id,
                step: applicant.step,
                cause: applicant.rejection_cause,
                question_info: applicant.questions_data,
                //uploadedFile: applicant.url,
              };
            });
            // Update the state with the new applicant_info array
            console.log(formattedApplicantInfo);
            setState((prevState) => ({
              ...prevState,
              visa_name: userdata.visa_name,
              visa_id: userdata.visa_id,
              arrival_date: userdata.date_of_arrival,
              applicant_info: formattedApplicantInfo,
            }));
          }

          localStorage.removeItem("applicationId");
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          // Handle error here
        });

      // localStorage.removeItem("updateId");
      // handleUpdateVisaCategory(updateobj.id);
    }
  }, []);

  const handleUpdateVisaCategory = async (id) => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      var data;
      // const updatedQuestions = state.applicant_info.question_info.map(
      //   (question) =>
      //     question.id === id ? { ...question, answer: value } : question
      // );
      const applicant = state.applicant_info[id]; // Assuming we are sending the first applicant's data
      const transformedData = applicant.question_info.map((item) => ({
        question_id: item.question_id,
        answer: item.answer,
      }));
      console.log(transformedData);
      if (applicant.visa_status == "Granted By Embassy") {
        data = {
          date_of_arrival: applicant.date_of_arrival,
          photo: applicant.photo,
          front_passport_photo: applicant.front_passport_photo,
          back_passport_photo: applicant.back_passport_photo,
          first_name: applicant.first_name,
          last_name: applicant.last_name,
          gender: applicant.gender,
          passport_number: applicant.passport_number,
          date_of_birth: applicant.date_of_birth,
          passport_issued_on: applicant.passport_issued_on,
          passport_valid_till: applicant.passport_valid_till,
          address_from_passport: applicant.address_from_passport,
          visa_status: applicant.visa_status,
          id: applicant.id,
          step: applicant.step,
          unique_id: unique,
          url: applicant.uploadedFile,
          questions: transformedData,

          // rejection_cause: applicant.cause,
        };
      } else if (
        applicant.visa_status == "Rejected By Admin" ||
        applicant.visa_status == "Rejected By Embassy"
      ) {
        data = {
          date_of_arrival: applicant.date_of_arrival,
          photo: applicant.photo,
          front_passport_photo: applicant.front_passport_photo,
          back_passport_photo: applicant.back_passport_photo,
          first_name: applicant.first_name,
          last_name: applicant.last_name,
          gender: applicant.gender,
          passport_number: applicant.passport_number,
          date_of_birth: applicant.date_of_birth,
          passport_issued_on: applicant.passport_issued_on,
          passport_valid_till: applicant.passport_valid_till,
          address_from_passport: applicant.address_from_passport,
          visa_status: applicant.visa_status,
          id: applicant.id,
          step: applicant.step,
          unique_id: unique,
          // url: applicant.uploadedFile,
          rejection_cause: applicant.cause,
          questions: transformedData,
        };
      } else {
        data = {
          date_of_arrival: applicant.date_of_arrival,
          photo: applicant.photo,
          front_passport_photo: applicant.front_passport_photo,
          back_passport_photo: applicant.back_passport_photo,
          first_name: applicant.first_name,
          last_name: applicant.last_name,
          gender: applicant.gender,
          passport_number: applicant.passport_number,
          date_of_birth: applicant.date_of_birth,
          passport_issued_on: applicant.passport_issued_on,
          passport_valid_till: applicant.passport_valid_till,
          address_from_passport: applicant.address_from_passport,
          visa_status: applicant.visa_status,
          id: applicant.id,
          step: applicant.step,
          unique_id: unique,
          questions: transformedData,
          // url: applicant.uploadedFile,
          // rejection_cause: applicant.cause,
        };
      }

      const requestOptions = {
        method: "POST",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        url + "applicationdetails/updateapplicationdetails",
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      if (actualData.status == 200) {
        setShowSnackbar(true);
        // setState({
        //   date_of_arrival: "",
        //   photo: "",
        //   front_passport_photo: "",
        //   back_passport_photo: "",
        //   first_name: "",
        //   last_name: "",
        //   gender: "",
        //   passport_number: "",
        //   date_of_birth: "",
        //   passport_issued_on: "",
        //   passport_valid_till: "",
        //   address_from_passport: "",
        //   visa_status: null,
        //   id: null,
        //   step: null,
        // });
        // navigate("/application_list");
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            {" "}
            <Typography variant="h3" color={"primary"}>
              Application Details
            </Typography>
          </div>

          <div
            onClick={() => {
              localStorage.clear();
              navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            {" "}
            <Typography
              variant="h4"
              color="primary"
              style={{ display: "flex", alignItems: "center" }}
            >
              Log out
              <MdLogout size={30} style={{ marginLeft: "8px" }} />
            </Typography>
          </div>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.contentMain}>
          <Grid item xs={6}>
            {/* <CustomInputField
              label="Visa Name"
              fullWidth
              value={state.visa_name}
              onChange={(e) =>
                setState({
                  ...state,
                  first_name: e.target.value,
                })
              }
            /> */}
            <p style={{ fontSize: "18px", fontWeight: "5px" }}>
              Visa Name: <span>{state.visa_name}</span>{" "}
            </p>
          </Grid>
          <Grid item xs={6}>
            {/* <CustomInputField
              label="Arrival Date"
              fullWidth
              value={state.arrival_date}
              onChange={(e) =>
                setState({
                  ...state,
                  arrival_date: e.target.value,
                })
              }
            /> */}
            <p style={{ fontSize: "18px", fontWeight: "5px" }}>
              Arrival Date:{" "}
              <span>
                {state.arrival_date == null ? "----" : state.arrival_date}
              </span>{" "}
            </p>
          </Grid>
          {state.applicant_info.length > 0 ? (
            <>
              <p style={{ marginTop: "8px" }}>Applicant Information</p>

              {state.applicant_info.map((applicant, index) => (
                <>
                  <Paper
                    className={classes.paper}
                    ref={(el) => (applicantRefs.current[index] = el)}
                  >
                    <Grid
                      container
                      spacing={3}
                      className="content"
                      alignItems={"end"}
                    >
                      <Grid item xs={12}>
                        {" "}
                        <p>
                          <b>Applicant {index + 1}</b>
                        </p>
                      </Grid>
                      <Grid item xs={6}>
                        <CustomInputField
                          label="First Name"
                          fullWidth
                          value={applicant.first_name}
                          onChange={(e) => {
                            const updatevar = [...state.applicant_info];
                            updatevar[index].first_name = e.target.value;
                            setState({
                              ...state,
                              applicant_info: updatevar,
                            });
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <CustomInputField
                          label="Last Name"
                          fullWidth
                          value={applicant.last_name}
                          onChange={(e) => {
                            const updatevar = [...state.applicant_info];
                            updatevar[index].last_name = e.target.value;
                            setState({
                              ...state,
                              applicant_info: updatevar,
                            });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <CustomInputAndSelectField
                          options={["male", "female", "others"]}
                          value={applicant.gender}
                          changeCallBack={(e, v) => {
                            const updatevar = [...state.applicant_info];
                            updatevar[index].gender = v;
                            setState({
                              ...state,
                              applicant_info: updatevar,
                            });
                          }}
                          label="Gender"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <CustomInputField
                          type="date"
                          label="Date of Birth"
                          fullWidth
                          value={applicant.date_of_birth}
                          onChange={(e) => {
                            const updatevar = [...state.applicant_info];
                            updatevar[index].date_of_birth = e.target.value;
                            setState({
                              ...state,
                              applicant_info: updatevar,
                            });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <CustomInputField
                          type="date"
                          label="Passport Valid Till"
                          fullWidth
                          value={applicant.passport_valid_till}
                          onChange={(e) => {
                            const updatevar = [...state.applicant_info];
                            updatevar[index].passport_valid_till =
                              e.target.value;
                            setState({
                              ...state,
                              applicant_info: updatevar,
                            });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <CustomInputField
                          type="date"
                          label="Passport Issued On"
                          fullWidth
                          value={applicant.passport_issued_on}
                          onChange={(e) => {
                            const updatevar = [...state.applicant_info];
                            updatevar[index].passport_issued_on =
                              e.target.value;
                            setState({
                              ...state,
                              applicant_info: updatevar,
                            });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <CustomInputField
                          type="number"
                          label="Passport Number"
                          fullWidth
                          value={applicant.passport_number}
                          onChange={(e) => {
                            const updatevar = [...state.applicant_info];
                            updatevar[index].passport_number = e.target.value;
                            setState({
                              ...state,
                              applicant_info: updatevar,
                            });
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <CustomInputField
                          label="Photo"
                          type="File"
                          fullWidth
                          onChange={(e) => {
                            const imageRef = ref(
                              storage,
                              `/application+${Math.random()}`
                            );
                            uploadBytes(imageRef, e.target.files[0]).then(
                              () => {
                                getDownloadURL(imageRef)
                                  .then((url) => {
                                    console.log(
                                      "variable value " + state.image
                                    );
                                    const updatevar = [...state.applicant_info];
                                    updatevar[index].photo = url;
                                    setState({
                                      ...state,
                                      applicant_info: updatevar,
                                    });
                                  })
                                  .catch((error) => {
                                    console.log(error);
                                  });
                              }
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        {applicant.photo && (
                          <>
                            <p>Photo</p>{" "}
                            <Avatar
                              variant="rounded"
                              alt="VISA"
                              src={applicant.photo}
                              style={{
                                margin: "auto",
                              }}
                            />
                          </>
                        )}
                      </Grid>
                      <Grid item xs={4}>
                        <CustomInputField
                          label="Passport Front Photo"
                          type="File"
                          fullWidth
                          onChange={(e) => {
                            const imageRef = ref(
                              storage,
                              `/fpassport+${Math.random()}`
                            );
                            uploadBytes(imageRef, e.target.files[0]).then(
                              () => {
                                getDownloadURL(imageRef)
                                  .then((url) => {
                                    // localStorage.setItem("fpassport", url);
                                    const updatevar = [...state.applicant_info];
                                    updatevar[index].front_passport_photo = url;
                                    setState({
                                      ...state,
                                      applicant_info: updatevar,
                                    });
                                  })
                                  .catch((error) => {
                                    console.log(error);
                                  });
                              }
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        {applicant.front_passport_photo && (
                          <>
                            <p>Passport Front Photo</p>{" "}
                            <Avatar
                              variant="rounded"
                              alt="VISA"
                              src={applicant.front_passport_photo}
                              style={{
                                margin: "auto",
                              }}
                            />
                          </>
                        )}
                      </Grid>
                      <Grid item xs={4}>
                        <CustomInputField
                          label="Passport Back Photo"
                          type="File"
                          fullWidth
                          onChange={(e) => {
                            const imageRef = ref(
                              storage,
                              `/bpassport+${Math.random()}`
                            );
                            uploadBytes(imageRef, e.target.files[0]).then(
                              () => {
                                getDownloadURL(imageRef)
                                  .then((url) => {
                                    // localStorage.setItem("bpassport", url);

                                    const updatevar = [...state.applicant_info];
                                    updatevar[index].front_passport_photo = url;
                                    setState({
                                      ...state,
                                      applicant_info: updatevar,
                                    });
                                  })
                                  .catch((error) => {
                                    console.log(error);
                                  });
                              }
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        {applicant.back_passport_photo && (
                          <>
                            <p>Passport Back Photo</p>{" "}
                            <Avatar
                              variant="rounded"
                              alt="VISA"
                              src={applicant.back_passport_photo}
                              style={{
                                margin: "auto",
                              }}
                            />
                          </>
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        <CustomInputField
                          label="Address From Passport"
                          fullWidth
                          value={applicant.address_from_passport}
                          onChange={(e) => {
                            const updatevar = [...state.applicant_info];
                            updatevar[index].address_from_passport =
                              e.target.value;
                            setState({
                              ...state,
                              applicant_info: updatevar,
                            });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <CustomInputAndSelectField
                          options={app_status.map((item) => item.status_name)}
                          value={applicant.visa_status}
                          changeCallBack={(e, v) => {
                            const updatevar = [...state.applicant_info];
                            updatevar[index].visa_status = v;

                            if (
                              v == "Granted By Embassy" ||
                              v == "Refused By Embassy" ||
                              v == "Refused By Admin"
                            ) {
                              //  setSelectedApplicantIndex(index);
                              //  setIsPopupOpen(true);
                              updatevar[index].isPopupOpen = true;
                              setState({
                                ...state,
                                applicant_info: updatevar,
                              });
                            } else {
                              setState({
                                ...state,
                                applicant_info: updatevar,
                              });
                            }
                          }}
                          label="Visa Status"
                          fullWidth
                          style={{
                            color: getStatusColor(applicant.visa_status),
                          }}
                        />
                      </Grid>
                      {applicant.visa_status == "Granted By Embassy" ? (
                        <>
                          <Grid item xs={4}>
                            <CustomInputField
                              label="Upload Visa"
                              value={applicant.uploadedFile}
                              type="File"
                              fullWidth
                              onChange={(e) => handleFileChange(index, e)}
                            />
                          </Grid>
                        </>
                      ) : applicant.visa_status == "Refused By Admin" ||
                        applicant.visa_status == "Refused By Embassy" ? (
                        <>
                          <Grid item xs={6}>
                            <CustomInputField
                              label="Cause"
                              fullWidth
                              value={applicant.cause}
                              onChange={(e) => {
                                const updatevar = [...state.applicant_info];
                                updatevar[index].cause = e.target.value;
                                setState({
                                  ...state,
                                  applicant_info: updatevar,
                                });
                              }}
                            />
                          </Grid>
                        </>
                      ) : (
                        <></>
                      )}

                      {applicant.question_info ? (
                        applicant.question_info.map((ch, idx) => (
                          <>
                            {ch.question_type == 1 && (
                              <Grid item xs={6}>
                                <CustomInputField
                                  label={ch.question_name}
                                  fullWidth
                                  value={ch.answer}
                                  onChange={(e) => {
                                    const updatevar = [...state.applicant_info];
                                    updatevar[index].question_info[idx].answer =
                                      e.target.value;
                                    setState({
                                      ...state,
                                      applicant_info: updatevar,
                                    });
                                  }}
                                />
                              </Grid>
                            )}
                            {ch.question_type == 2 && (
                              <Grid item xs={6}>
                                <CustomInputField
                                  type="number"
                                  label={ch.question_name}
                                  fullWidth
                                  value={ch.answer}
                                  onChange={(e) => {
                                    const updatevar = [...state.applicant_info];
                                    updatevar[index].question_info[idx].answer =
                                      e.target.value;
                                    setState({
                                      ...state,
                                      applicant_info: updatevar,
                                    });
                                  }}
                                />
                              </Grid>
                            )}
                            {ch.question_type == 3 && (
                              <>
                                <Grid item xs={4}>
                                  <CustomInputField
                                    label="Photo"
                                    type="File"
                                    fullWidth
                                    onChange={(e) => {
                                      const imageRef = ref(
                                        storage,
                                        `/application+${Math.random()}`
                                      );
                                      uploadBytes(
                                        imageRef,
                                        e.target.files[0]
                                      ).then(() => {
                                        getDownloadURL(imageRef)
                                          .then((url) => {
                                            console.log(
                                              "variable value " + state.image
                                            );
                                            const updatevar = [
                                              ...state.applicant_info,
                                            ];
                                            updatevar[index].question_info[
                                              idx
                                            ].answer = url;
                                            setState({
                                              ...state,
                                              applicant_info: updatevar,
                                            });
                                          })
                                          .catch((error) => {
                                            console.log(error);
                                          });
                                      });
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={2}>
                                  {ch.answer && (
                                    <>
                                      <p>Photo</p>{" "}
                                      <Avatar
                                        variant="rounded"
                                        alt="VISA"
                                        src={ch.answer}
                                        style={{
                                          margin: "auto",
                                        }}
                                      />
                                    </>
                                  )}
                                </Grid>
                              </>
                            )}
                            {ch.question_type == 4 && (
                              <Grid item xs={6}>
                                <CustomInputField
                                  type="date"
                                  label={ch.question_name}
                                  fullWidth
                                  value={ch.answer}
                                  onChange={(e) => {
                                    const updatevar = [...state.applicant_info];
                                    updatevar[index].question_info[idx].answer =
                                      e.target.value;
                                    setState({
                                      ...state,
                                      applicant_info: updatevar,
                                    });
                                  }}
                                />
                              </Grid>
                            )}
                            {ch.question_type == 5 && (
                              <Grid item xs={6}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={ch.answer} // Assuming `ch.answer` is boolean
                                      onChange={(e) => {
                                        console.log(e.target.checked);
                                        const updatevar = [
                                          ...state.applicant_info,
                                        ];
                                        updatevar[index].question_info[
                                          idx
                                        ].answer = e.target.checked;
                                        setState({
                                          ...state,
                                          applicant_info: updatevar,
                                        });
                                      }}
                                      fullWidth
                                    />
                                  }
                                  label={ch.question_name}
                                />
                                {/* <CustomInputField
                                  type="checkbox"
                                  label={ch.question_name}
                                  fullWidth
                                  value={ch.answer}
                                  onChange={(e) => {
                                    const updatevar = [...state.applicant_info];
                                    updatevar[index].question_info[idx].answer =
                                      e.target.value;
                                    setState({
                                      ...state,
                                      applicant_info: updatevar,
                                    });
                                  }}
                                /> */}
                              </Grid>
                            )}
                            {ch.question_type == 6 && (
                              <Grid item xs={6}>
                                <TextField
                                  label={ch.question_name}
                                  multiline
                                  value={ch.answer}
                                  onChange={(e) => {
                                    const updatevar = [...state.applicant_info];
                                    updatevar[index].question_info[idx].answer =
                                      e.target.value;
                                    setState({
                                      ...state,
                                      applicant_info: updatevar,
                                    });
                                  }}
                                  rows={4} // You can specify the number of rows here
                                  variant="outlined" // You can use other variants like 'filled' or 'standard'
                                  fullWidth // Makes the TextField take the full width of its container
                                />
                              </Grid>
                            )}
                            {ch.question_type == 7 && (
                              <Grid item xs={6}>
                                <CustomInputAndSelectField
                                  options={ch.answers.map(
                                    (item) => item.choice
                                  )}
                                  value={ch.answer}
                                  changeCallBack={(e, v) => {
                                    const updatevar = [...state.applicant_info];
                                    updatevar[index].question_info[idx].answer =
                                      v;
                                    setState({
                                      ...state,
                                      applicant_info: updatevar,
                                    });
                                  }}
                                  label={ch.question_name}
                                  fullWidth
                                />
                              </Grid>
                            )}
                            {ch.question_type == 8 && (
                              <Grid item xs={6}>
                                <FormGroup>
                                  <p>{ch.question_name}</p>
                                  {ch.answers.map((option) => (
                                    <FormControlLabel
                                      key={option.choice}
                                      control={
                                        <Checkbox
                                          checked={ch.answer == option.choice}
                                          onChange={(e) => {
                                            const updatevar = [
                                              ...state.applicant_info,
                                            ];
                                            updatevar[index].question_info[
                                              idx
                                            ].answer = e.target.name;
                                            setState({
                                              ...state,
                                              applicant_info: updatevar,
                                            });
                                          }}
                                          // onChange={handleChange}
                                          name={option.choice}
                                        />
                                      }
                                      label={option.choice}
                                    />
                                  ))}
                                </FormGroup>
                              </Grid>
                            )}
                            {ch.question_type == 9 && (
                              <Grid item xs={6}>
                                <FormControl component="fieldset">
                                  <FormLabel component="legend">
                                    {ch.question_name}
                                  </FormLabel>
                                  <RadioGroup
                                    row
                                    aria-label={ch.question_name}
                                    name={ch.question_name}
                                    value={ch.answer}
                                    onChange={(e) => {
                                      const updatevar = [
                                        ...state.applicant_info,
                                      ];
                                      updatevar[index].question_info[
                                        idx
                                      ].answer = e.target.value;
                                      setState({
                                        ...state,
                                        applicant_info: updatevar,
                                      });
                                    }}
                                    // onChange={(e) =>
                                    //   setState({
                                    //     ...state,
                                    //     gender: e.target.value,
                                    //   })
                                    // }
                                  >
                                    {ch.answers.map((option) => (
                                      <FormControlLabel
                                        key={option.choice} // Add key prop for better rendering performance
                                        value={option.choice}
                                        control={<Radio />}
                                        label={option.choice}
                                      />
                                    ))}
                                  </RadioGroup>
                                </FormControl>
                              </Grid>
                            )}
                          </>
                        ))
                      ) : (
                        <p></p>
                      )}

                      <Grid item xs={2} alignItems="end">
                        <CustomButton
                          text={"Update"}
                          variant="contained"
                          color="warning"
                          fullWidth
                          onClick={(e) => {
                            handleUpdateVisaCategory(index);
                          }}
                        />
                      </Grid>

                      <Snackbar
                        open={showSnackbar}
                        autoHideDuration={6000}
                        onClose={() => setShowSnackbar(false)}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                      >
                        <Alert
                          onClose={() => setShowSnackbar(false)}
                          severity="success"
                          icon={<FaCheckCircle fontSize="inherit" />}
                        >
                          Successfully updated!
                        </Alert>
                      </Snackbar>
                      <Grid item xs={2} key={index} alignItems="end">
                        <CustomButton
                          text={"Preview"}
                          variant="contained"
                          fullWidth
                          onClick={() => handleGeneratePDF(index)}
                        />
                      </Grid>
                      <Grid item xs={10} alignItems="end"></Grid>
                    </Grid>
                    {selectedApplicant && selectedApplicant.index === index && (
                      <ApplicantPDF
                        applicant={selectedApplicant}
                        index={selectedApplicant.index}
                        onDownload={handleDownload}
                      />
                    )}
                  </Paper>
                </>
              ))}
            </>
          ) : (
            <p></p>
          )}
        </div>
      </div>
    </div>
  );
}
