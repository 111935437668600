import { Button, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Divider } from "@mui/material";
import { AiFillFlag } from "react-icons/ai";
import DashboardLayout from "../../layouts";
import countryStyles from "../../styles/pages/CountryPage/index.style";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomInputAndSelectField from "../../components/CustomInputAndSelectField/Index";
import axios from "axios";
import endPoints from "../../config/endPoint";
import { convertFromRaw, EditorState, convertToRaw } from "draft-js";
import { storage } from "../../firebase_config";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router";
import { Avatar } from "@mui/material";
import CustomButton from "../../components/CustomButton/Index";
import { FaEdit, FaEye, FaTimes, FaUserFriends } from "react-icons/fa";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { MdLogout } from "react-icons/md";
import { useLocation } from "react-router-dom";
import url from "../../API";
export default function QuestionPage() {
  const classes = countryStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { questionId } = location.state || {};
  const { visaId } = location.state || {};
  const { visa_name } = location.state || {};
  const token = localStorage.getItem("tkn");
  const data = localStorage.getItem("data");
  const parsedData = JSON.parse(data);
  const [visaInformation, setVisaInformation] = React.useState([]);
  const [typelist, setTypelist] = React.useState();
  const [type_question, setQuestion] = React.useState("");
  // const [visaId, setVisaId] = React.useState("");
  const [state, setState] = React.useState({
    visa_name: "",
    visa_id: "",
    question_info: [
      {
        type_question: "",
        type_list: [
          "text input",
          "Number",
          "Upload",
          "Date Picker",
          "Toggle",
          "Text Area",
          "Drop Down",
          "Check box",
          "Radio",
        ],
        question_type: "",
        question_type_id: "",
        name: "",
        choice: [],
        required: "",
      },
    ],
  });
  const [alignment, setAlignment] = React.useState("");

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  function table() {
    axios
      .get(url + "visainformation/getallvisainformation", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // Handle the response
        setVisaInformation(response.data.Visa_Information);
        console.log(response.data.Visa_Information);
      })
      .catch((error) => {
        // Handle errors
        console.error("Error fetching data:", error);
      });
  }
  const handleStateChange = (name, value) => {
    if (!!name) {
      setState({
        ...state,
        [name]: value,
      });
    }
  };
  const handleEntryTypeChange = (v) => {
    const selectedEntryTypeName = v;

    const selectedEntryType = visaInformation.find(
      (item) => item.visa_name === selectedEntryTypeName
    );

    setState((prevState) => ({
      ...prevState,
      visa_name: v,
      visa_id: selectedEntryType ? selectedEntryType.id : "",
    }));
    console.log(state);
  };
  React.useEffect(() => {
    table();
    if (questionId) {
      console.log(questionId);
      // const updateobj = JSON.parse(localStorage.getItem("updateQuestion"));
      // const idx = updateobj.question_type;
      // const question_type_name = state.question_info[0].type_list[idx - 1];
      // console.log(question_type_name);

      // const updatedQuestion = {
      //   type_question: idx === 7 || idx === 8 || idx === 9 ? "multiple" : "",
      //   type_list: state.question_info[0].type_list,
      //   question_type: question_type_name,
      //   question_type_id: updateobj.question_type,
      //   name: updateobj.question_name,
      //   choice:
      //     idx === 7 || idx === 8 || idx === 9
      //       ? updateobj.choice.map((choice) => ({ choice_title: choice }))
      //       : [],
      //   required: updateobj.required == true ? "True" : "False",
      // };
      const type_list = [
        "text input",
        "Number",
        "Upload",
        "Date Picker",
        "Toggle",
        "Text Area",
        "Drop Down",
        "Check box",
        "Radio",
      ];
      setState((prevState) => ({
        ...prevState,
        visa_name: questionId.visa_name,
        isUpdate: true,
        visa_id: questionId.visa_id,
        id: questionId.id,
        question_info: [
          {
            ...prevState.question_info[0],
            type_question: [7, 8, 9].includes(questionId.question_type)
              ? "multiple"
              : "",
            type_list: [
              "text input",
              "Number",
              "Upload",
              "Date Picker",
              "Toggle",
              "Text Area",
              "Drop Down",
              "Check box",
              "Radio",
            ], // Keep other properties unchanged
            name: questionId.question_name,
            question_type: type_list[questionId.question_type - 1],
            question_type_id: questionId.question_type, // Assuming map_id is used for question_type_id
            choice: questionId.choices.map((item) => ({ choice_title: item })),
            required: questionId.required_or_not,
          },
        ],
      }));
      // setState((prevState) => ({
      //   ...prevState,
      //   visa_name: updateobj.visa_name,
      //   visa_id: updateobj.visa_id,
      //   question_info: [updatedQuestion],
      //   id: updateobj.map_id,
      //   isUpdate: true,
      // }));

      setAlignment(questionId.required == true ? "True" : "False");
    }
    if (visaId && visa_name) {
      console.log(visaId);
      setState((prevState) => ({
        ...prevState,
        visa_name: visa_name,

        visa_id: visaId,
      }));
    }
    console.log(state);
  }, []);

  const handleUpdateInfo = (id) => {
    // Loop through each question in the question_info array
    state.question_info.forEach((question) => {
      // Check if question_type_id is one of the specified values
      if ([7, 8, 9].includes(question.question_type_id)) {
        const data = question.choice.map((item) => item.choice_title);
        axios
          .post(
            url + "question/updatequestion",
            {
              id: id,
              visa_id: state.visa_id,
              question_name: question.name,
              question_type: question.question_type_id,
              choice: data,
              required_or_not: question.required,
              // description: JSON.stringify(question.description), // Assuming you have a description field
            },
            {
              headers: {
                "Content-Type": "application/json", // Example header
                Authorization: `Bearer ${token}`, // Example authorization header
              },
            }
          )
          .then((r) => {
            console.log(r);
            // Navigate only after the last request is done (optional)
            if (
              question === state.question_info[state.question_info.length - 1]
            ) {
              localStorage.removeItem("updateQuestion");
              navigate("/question_list", {
                state: { visaId: questionId.visa_id },
              });
            }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
            // Handle error here
          });
      } else {
        axios
          .post(
            url + "question/updatequestion",
            {
              id: id,
              visa_id: state.visa_id,
              question_name: question.name,
              question_type: question.question_type_id,
              choice: [],
              required_or_not: question.required,
              // description: JSON.stringify(question.description), // Assuming you have a description field
            },
            {
              headers: {
                "Content-Type": "application/json", // Example header
                Authorization: `Bearer ${token}`, // Example authorization header
              },
            }
          )
          .then((r) => {
            console.log(r);
            console.log(visaId);
            // Navigate only after the last request is done (optional)
            if (
              question === state.question_info[state.question_info.length - 1]
            ) {
              console.log(visaId);
              localStorage.removeItem("updateQuestion");
              navigate("/question_list", {
                state: { visaId: questionId.visa_id },
              });
            }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
            // Handle error here
          });
      }
    });
  };

  const handleCreateInfo = () => {
    // Loop through each question in the question_info array
    state.question_info.forEach((question) => {
      // Check if question_type_id is one of the specified values
      if ([7, 8, 9].includes(question.question_type_id)) {
        const data = question.choice.map((item) => item.choice_title);
        axios
          .post(
            url + "question/createquestion",
            {
              visa_id: visaId,
              question_name: question.name,
              question_type: question.question_type_id,
              choice: data,
              required_or_not: question.required,
              //  description: JSON.stringify(question.description), // Assuming you have a description field
            },
            {
              headers: {
                "Content-Type": "application/json", // Example header
                Authorization: `Bearer ${token}`, // Example authorization header
              },
            }
          )
          .then((r) => {
            console.log(r);

            // Navigate only after the last request is done (optional)
            if (
              question === state.question_info[state.question_info.length - 1]
            ) {
              console.log(visaId);
              navigate("/question_list", {
                state: { visaId: visaId },
              });
            }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
            // Handle error here
          });
      } else {
        axios
          .post(
            url + "question/createquestion",
            {
              visa_id: state.visa_id,
              question_name: question.name,
              question_type: question.question_type_id,
              required_or_not: question.required,
              //  description: JSON.stringify(question.description), // Assuming you have a description field
            },
            {
              headers: {
                "Content-Type": "application/json", // Example header
                Authorization: `Bearer ${token}`, // Example authorization header
              },
            }
          )
          .then((r) => {
            console.log(r);
            console.log(visaId);
            // Navigate only after the last request is done (optional)
            if (
              question === state.question_info[state.question_info.length - 1]
            ) {
              navigate("/question_list", {
                state: { visaId: visaId },
              });
            }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
            // Handle error here
          });
      }
    });
  };

  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography variant="h3" color={"primary"}>
              Question
            </Typography>
          </div>
          <div
            onClick={() => {
              localStorage.clear();
              navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            {" "}
            <Typography
              variant="h4"
              color="primary"
              style={{ display: "flex", alignItems: "center" }}
            >
              Log out
              <MdLogout size={30} style={{ marginLeft: "8px" }} />
            </Typography>
          </div>
        </div>
        <Divider className={classes.divider} />
        <Grid container spacing={4} className={classes.contentMain}>
          <Grid item xs={12}>
            <CustomInputAndSelectField
              options={visaInformation.map((item) => item.visa_name)} // Set options from entry type names
              value={state.visa_name}
              label="Visa"
              fullWidth
              changeCallBack={(e, v) => {
                console.log(v);

                const selectedEntryType = visaInformation.find(
                  (item) => item.visa_name === v
                );

                setState((prevState) => ({
                  ...prevState,
                  visa_name: v,
                  visa_id: selectedEntryType ? selectedEntryType.id : "",
                }));
                //handleEntryTypeChange(v);
              }}
              // Handle onChange event
            />
          </Grid>
          {/* ------------------------- */}
          <div className={classes.contentMain} style={{ width: "100%" }}>
            <Paper className={classes.paper} style={{ marginLeft: "16px" }}>
              <Grid
                container
                spacing={3}
                className="content"
                alignItems={"end"}
              >
                {state.question_info ? (
                  state.question_info.map((ch, idx) => (
                    <>
                      <Grid item xs={12}>
                        <CustomInputAndSelectField
                          options={ch.type_list.map((item) => item)} // Set options from entry type names
                          value={ch.question_type}
                          label="Question type"
                          fullWidth
                          changeCallBack={(e, v) => {
                            const idxSelect = ch.type_list.indexOf(v);

                            setState((prevState) => {
                              const newQuestionInfo =
                                prevState.question_info.map((info, index) => {
                                  if (index === idx) {
                                    var updatedInfo = {};

                                    if (
                                      v === "Drop Down" ||
                                      v === "Check box" ||
                                      v === "Radio"
                                    ) {
                                      updatedInfo = {
                                        ...info,
                                        type_question: "multiple",
                                        question_type: v,
                                        question_type_id: idxSelect + 1,
                                        choice: [
                                          ...info.choice,
                                          { choice_title: "" },
                                        ],
                                      };
                                      // Add a new choice object if the type is one of the specified types
                                    } else {
                                      updatedInfo = {
                                        ...info,
                                        type_question: "",
                                        question_type: v,
                                        question_type_id: idxSelect + 1,
                                        choice: [],
                                      };
                                    }

                                    return updatedInfo;
                                  }
                                  return info;
                                });

                              return {
                                ...prevState,
                                question_info: newQuestionInfo,
                              };
                            });
                          }}

                          // Handle onChange event
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CustomInputField
                          label="Question"
                          fullWidth
                          value={ch.name}
                          name="name"
                          onChange={(e) => {
                            const newQuestionInfo = [...state.question_info];
                            // Update the name field of the specific question object
                            newQuestionInfo[idx].name = e.target.value;
                            // Update the state with the modified question_info array
                            setState({
                              ...state,
                              question_info: newQuestionInfo,
                            });
                          }}
                        />
                      </Grid>

                      {ch.type_question ? (
                        <>
                          {ch.choice.map((ch2, idx2) => (
                            <>
                              <Grid item xs={7}>
                                <CustomInputField
                                  label="Choice"
                                  fullWidth
                                  value={ch2.choice_title}
                                  name="name"
                                  onChange={(e) => {
                                    const newQuestionInfo = [
                                      ...state.question_info,
                                    ];
                                    newQuestionInfo[idx].choice[
                                      idx2
                                    ].choice_title = e.target.value;
                                    setState({
                                      ...state,
                                      question_info: newQuestionInfo,
                                    });
                                  }}
                                />
                              </Grid>
                              <Grid
                                style={{
                                  paddingTop: "64px",
                                  paddingLeft: "20px",
                                }}
                              >
                                {" "}
                                <span className={classes.actionBtn}>
                                  <CustomButton
                                    variant="contained"
                                    color="error"
                                    onClick={() => {
                                      const newQuestionInfo = [
                                        ...state.question_info,
                                      ];
                                      newQuestionInfo[idx].choice.splice(
                                        idx2,
                                        1
                                      );
                                      setState({
                                        ...state,
                                        question_info: newQuestionInfo,
                                      });
                                    }}
                                    icon={<FaTimes />}
                                  />
                                </span>
                              </Grid>
                            </>
                          ))}

                          <Grid item xs={12} md={4}>
                            <Button
                              style={{ marginTop: "18px" }}
                              variant="contained"
                              fullWidth
                              onClick={() => {
                                const newQuestionInfo = [
                                  ...state.question_info,
                                ];
                                // Add a new choice object to the specific question_info object
                                newQuestionInfo[idx].choice.push({
                                  choice_title: "",
                                });
                                // Update the state with the modified question_info array
                                setState({
                                  ...state,
                                  question_info: newQuestionInfo,
                                });
                              }}
                            >
                              Add Choice
                            </Button>
                          </Grid>
                        </>
                      ) : (
                        <p></p>
                      )}
                      <Grid item xs={3}>
                        <p>Required</p>
                        <ToggleButtonGroup
                          color="warning"
                          value={ch.required ? "True" : "False"}
                          exclusive
                          onChange={(e, newAlignment) => {
                            const newQuestionInfo = [...state.question_info];
                            newQuestionInfo[idx].required =
                              newAlignment == "True" ? true : false;
                            setState({
                              ...state,
                              question_info: newQuestionInfo,
                            });
                          }}
                          aria-label="Required"
                        >
                          <ToggleButton value="True" style={{ color: "black" }}>
                            Yes
                          </ToggleButton>
                          <ToggleButton
                            value="False"
                            style={{ color: "black" }}
                          >
                            No
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Grid>

                      <Grid style={{ paddingTop: "64px", paddingLeft: "20px" }}>
                        {" "}
                        <span className={classes.actionBtn}>
                          <CustomButton
                            variant="contained"
                            color="error"
                            onClick={(e) => {
                              const newArray = [...state.question_info];
                              const itemToRemove = newArray[idx];

                              // if (itemToRemove.id !== "") {
                              //   // Call your API here
                              //   // Example:
                              //   axios
                              //     .post(
                              //       url +"faqforvisa/deletefaqforvisa",
                              //       {
                              //         id: itemToRemove.id,
                              //       },
                              //       {
                              //         headers: {
                              //           /* Your headers here */
                              //           "Content-Type": "application/json", // Example header
                              //           Authorization: `Bearer ${token}`, // Example authorization header
                              //         },
                              //       }
                              //     )
                              //     .then((r) => {
                              //       newArray.splice(idx, 1);
                              //       setState({
                              //         ...state,
                              //         question_info: newArray,
                              //       });
                              //     })
                              //     .catch((error) => {
                              //       console.error(
                              //         "Error fetching data:",
                              //         error
                              //       );
                              //       // Handle error here
                              //     });
                              // } else {
                              // If id is empty, directly remove the item from the array
                              newArray.splice(idx, 1);
                              setState({ ...state, question_info: newArray });
                              //   }
                            }}
                            icon={<FaTimes />}
                          />
                        </span>
                      </Grid>
                    </>
                  ))
                ) : (
                  <p></p>
                )}
                <Grid item xs={12} md={4}>
                  <Button
                    style={{ marginTop: "18px" }}
                    variant="contained"
                    fullWidth
                    onClick={() => {
                      setState({
                        ...state,
                        question_info: [
                          ...state.question_info,
                          {
                            question_type: "",
                            question_type_id: "",
                            type_question: "",
                            type_list: [
                              "text input",
                              "Number",
                              "Upload",
                              "Date Picker",
                              "Toggle",
                              "Text Area",
                              "Drop Down",
                              "Check box",
                              "Radio",
                            ],
                            name: "",
                            choice: [],
                          },
                        ],
                      });
                    }}
                  >
                    Add Question
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </div>
          <Grid item xs={12}>
            {state.isUpdate ? (
              <Button
                variant="contained"
                fullWidth
                color="warning"
                onClick={(e) => {
                  // if (!!state.name) {
                  handleUpdateInfo(state.id);
                  // }
                }}
              >
                Update
              </Button>
            ) : (
              <Button
                variant="contained"
                fullWidth
                onClick={(e) => {
                  handleCreateInfo();
                }}
              >
                Create
              </Button>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
