import { Avatar, Divider, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { FaEdit, FaTimes, FaUserFriends } from "react-icons/fa";
import { RiVisaFill } from "react-icons/ri";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomPill from "../../components/CustomPill/Index";
import CustomTable from "../../components/CustomTable/Index";
import endPoints from "../../config/endPoint";
import DashboardLayout from "../../layouts";
import offerStyles from "../../styles/pages/VisaCategory/index.style";
import axios from "axios";
import CustomTimeTable from "../../components/CustomTimeTable/Index";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import CustomInputAndSelectField from "../../components/CustomInputAndSelectField/Index";
import { useNavigate } from "react-router-dom";
import { MdLogout } from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import url from "../../API";
export default function CouponAdd() {
  const classes = offerStyles();
  const navigate = useNavigate();
  const token = localStorage.getItem("tkn");
  const formData = new FormData();
  const [mainlist, setMainList] = React.useState([]);
  const [state, setState] = React.useState({
    coupon_code: "",
    searchText: "",
    off_value: "",
    minimum_amount: "",
    maximum_discount: "",
    off_type: " ",
    coupon_description: EditorState.createEmpty(),
    isUpdate: false,
  });
  const [couponlist, setcouponlist] = React.useState([]);
  const [visaidList, setVisaidList] = React.useState([]);
  async function table() {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(url + "coupon/getallcoupon", requestOptions);
      const actualData = await res.json();
      console.log(actualData);
      setcouponlist(actualData.Coupon);
    } catch (e) {
      console.log(e);
    }
  }

  React.useEffect(() => {
    table();
  }, []);

  const handleSaveVisaCategory = () => {
    axios
      .post(
        url + "coupon/createcoupon",
        {
          coupon_code: state.coupon_code,
          off_value: state.off_value,
          minimum_amount: state.minimum_amount,
          maximum_discount: state.maximum_discount,
          off_type: state.off_type,
          coupon_description: JSON.stringify(
            convertToRaw(state.coupon_description.getCurrentContent())
          ),
        },
        {
          headers: {
            /* Your headers here */
            "Content-Type": "application/json", // Example header
            Authorization: `Bearer ${token}`, // Example authorization header
          },
        }
      )
      .then((r) => table())
      .catch((error) => {
        console.error("Error fetching data:", error);
        // Handle error here
      });
    setState({
      coupon_code: "",
      searchText: "",
      off_value: "",
      minimum_amount: "",
      maximum_discount: "",
      off_type: " ",
      coupon_description: EditorState.createEmpty(),
    });
  };
  const handleVisaCategoryDelete = (idx) => {
    axios
      .post(
        url + "coupon/deletecoupon",
        {
          id: idx,
        },
        {
          headers: {
            /* Your headers here */
            "Content-Type": "application/json", // Example header
            Authorization: `Bearer ${token}`, // Example authorization header
          },
        }
      )
      .then((r) => table())
      .catch((error) => {
        console.error("Error fetching data:", error);
        // Handle error here
      });
  };
  const handleUpdateVisaCategory = (idx) => {
    axios
      .post(
        url + "coupon/updatecoupon",
        {
          id: idx,
          coupon_code: state.coupon_code,
          off_value: state.off_value,
          minimum_amount: state.minimum_amount,
          maximum_discount: state.maximum_discount,
          off_type: state.off_type,
          coupon_description: JSON.stringify(
            convertToRaw(state.coupon_description.getCurrentContent())
          ),
        },
        {
          headers: {
            /* Your headers here */
            "Content-Type": "application/json", // Example header
            Authorization: `Bearer ${token}`, // Example authorization header
          },
        }
      )
      .then((r) => table())
      .catch((error) => {
        console.error("Error fetching data:", error);
        // Handle error here
      });
    setState({
      coupon_code: "",
      searchText: "",
      off_value: "",
      minimum_amount: "",
      maximum_discount: "",
      off_type: " ",
      coupon_description: EditorState.createEmpty(),
      isUpdate: false,
    });
  };
  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            {" "}
            <Typography variant="h3" color={"primary"}>
              Coupon
            </Typography>
          </div>

          <div
            onClick={() => {
              localStorage.clear();
              navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            {" "}
            <Typography
              variant="h4"
              color="primary"
              style={{ display: "flex", alignItems: "center" }}
            >
              Log out
              <MdLogout size={30} style={{ marginLeft: "8px" }} />
            </Typography>
          </div>
        </div>

        <Divider className={classes.divider} />
        <div className={classes.contentMain}>
          <p className={classes.title}>Coupon</p>
          <Paper className={classes.paper}>
            <Grid container spacing={3} className="content" alignItems={"end"}>
              <Grid item xs={5}>
                <CustomInputField
                  label="Coupon Code"
                  type="number"
                  fullWidth
                  value={state.coupon_code}
                  onChange={(e) =>
                    setState({
                      ...state,
                      coupon_code: parseInt(e.target.value),
                    })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                Coupon Description
                <Editor
                  label="Coupon Description"
                  editorState={state.coupon_description}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  // value={state.country_description}
                  onEditorStateChange={(e) =>
                    setState({
                      ...state,
                      coupon_description: e,
                    })
                  }
                />
              </Grid>
              <Grid item xs={5}>
                <CustomInputField
                  label="Minimum Amount"
                  type="number"
                  fullWidth
                  value={state.minimum_amount}
                  onChange={(e) =>
                    setState({
                      ...state,
                      minimum_amount: parseInt(e.target.value),
                    })
                  }
                />
              </Grid>
              <Grid item xs={5}>
                <CustomInputField
                  label="Maximum Discount"
                  type="number"
                  fullWidth
                  value={state.maximum_discount}
                  onChange={(e) =>
                    setState({
                      ...state,
                      maximum_discount: parseInt(e.target.value),
                    })
                  }
                />
              </Grid>
              <Grid item xs={5}>
                <CustomInputAndSelectField
                  options={["Percentage off", "Flat off"]}
                  value={
                    state.off_type == 1
                      ? "Percentage off"
                      : state.off_type == 2
                      ? "Flat off"
                      : ""
                  }
                  changeCallBack={(e, v) => {
                    console.log(v);
                    if (v == "Percentage off") {
                      setState({
                        ...state,
                        off_type: 1,
                      });
                    } else {
                      setState({
                        ...state,
                        off_type: 2,
                      });
                    }
                  }}
                  label="Offer type"
                  fullWidth
                />
              </Grid>
              <Grid item xs={5}>
                <CustomInputField
                  label="Offer Value"
                  type="number"
                  fullWidth
                  value={state.off_value}
                  onChange={(e) =>
                    setState({
                      ...state,
                      off_value: parseInt(e.target.value),
                    })
                  }
                />
              </Grid>
              {/* <Grid item xs={5}>
                <CustomInputField
                  type="File"
                  fullWidth
                  onChange={(e) =>
                    setState({
                      ...state,
                      visaImg: e.target.value,
                    })
                  }
                />
              </Grid> */}
              <Grid item xs={2} alignItems="end">
                {state.isUpdate ? (
                  <CustomButton
                    text={"Update"}
                    variant="contained"
                    color="warning"
                    fullWidth
                    onClick={(e) => {
                      if (
                        !!state.coupon_code &&
                        !!state.off_value &&
                        !!state.minimum_amount &&
                        !!state.maximum_discount &&
                        !!state.off_type &&
                        !!state.coupon_description.getCurrentContent().hasText()
                      ) {
                        handleUpdateVisaCategory(state.id);
                      } else {
                        toast.error("Fill all the information", {
                          position: "top-center",
                        });
                      }
                    }}
                    // onClick={(e) =>
                    //   {
                    //   handleUpdateVisaCategory(state.id);
                    //   }}
                  />
                ) : (
                  <CustomButton
                    text={"create"}
                    variant="contained"
                    fullWidth
                    onClick={(e) => {
                      if (
                        !!state.coupon_code &&
                        !!state.off_value &&
                        !!state.minimum_amount &&
                        !!state.maximum_discount &&
                        !!state.off_type &&
                        !!state.coupon_description.getCurrentContent().hasText()
                      ) {
                        handleSaveVisaCategory();
                      } else {
                        toast.error("Fill all the information", {
                          position: "top-center",
                        });
                      }
                    }}
                    // onClick={(e) => {
                    //   handleSaveVisaCategory();
                    // }}
                  />
                )}
              </Grid>
            </Grid>
            <ToastContainer />
          </Paper>
          <p className={classes.title}>Coupon List</p>
          <Paper className={classes.paper}>
            <CustomInputField
              label="Search..."
              fullWidth
              value={state.searchText}
              onChange={(e) =>
                setState({
                  ...state,
                  searchText: e.target.value,
                })
              }
              className="searchField"
            />
            <CustomTimeTable
              fullWidth
              cols={[
                "Sl No.",
                "Coupon Code",
                "Coupon Description",
                "Offer Type",
                "Offer Value",
                "Max Amount",
                "Min Amount",
                "Action",
              ]}
              rows={[
                ...couponlist
                  .filter((l) => {
                    const cpn_code =
                      (l.coupon_code ?? "").toString().toLowerCase() || "";

                    const min_am =
                      (l.minimum_amount ?? "").toString().toLowerCase() || "";

                    const max_am =
                      (l.maximum_discount ?? "").toString().toLowerCase() || "";
                    // const off_val = l.off_value?.toLowerCase() || "";
                    const off_val =
                      (l.off_value ?? "").toString().toLowerCase() || "";
                    const cpn_desc =
                      JSON.parse(
                        l.coupon_description
                      )?.blocks[0].text?.toLowerCase() || "";

                    const searchLower = (state.searchText || "").toLowerCase();

                    return (
                      cpn_code.includes(searchLower) ||
                      min_am.includes(searchLower) ||
                      max_am.includes(searchLower) ||
                      off_val.includes(searchLower) ||
                      cpn_desc.includes(searchLower) ||
                      searchLower === undefined
                    );
                  })

                  .map((vs, idx) => [
                    idx + 1,
                    vs.coupon_code,
                    JSON.parse(vs.coupon_description).blocks[0].text,
                    vs.off_type == 1
                      ? "Percentage off"
                      : vs.off_type == 2
                      ? "Flat off"
                      : "",
                    vs.off_value,
                    vs.minimum_amount,
                    vs.maximum_discount,
                    <span className={classes.actionBtn}>
                      <CustomButton
                        variant="contained"
                        color="error"
                        onClick={(e) => handleVisaCategoryDelete(vs.id)}
                        icon={<FaTimes />}
                      />
                      <CustomButton
                        variant="contained"
                        color="warning"
                        onClick={(e) =>
                          setState({
                            coupon_code: vs.coupon_code,
                            coupon_description: EditorState.createWithContent(
                              convertFromRaw(JSON.parse(vs.coupon_description))
                            ),
                            minimum_amount: vs.minimum_amount,
                            maximum_discount: vs.maximum_discount,
                            off_type: vs.off_type,
                            off_value: vs.off_value,
                            isUpdate: true,
                            id: vs.id,
                          })
                        }
                        icon={<FaEdit />}
                      />
                    </span>,
                  ]),
              ]}
            />
          </Paper>
        </div>
      </div>
    </div>
  );
}
