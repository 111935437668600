import { Box, Card, CardHeader } from "@mui/material";
import axios from "axios";
import { merge } from "lodash";
import React from "react";
import ReactApexChart from "react-apexcharts";
import endPoints from "../../../config/endPoint";
import BaseOptionChart from "./BaseOptionChart";

export default function RevenueChart({ date }) {
  const [label, setLabel] = React.useState([]);
  const [chartData, setChartData] = React.useState([]);
  const [error, setError] = React.useState(false);

  const defaultData = [
    { _id: new Date().setDate(new Date().getDate() - 4), total_count: 100 },
    { _id: new Date().setDate(new Date().getDate() - 3), total_count: 150 },
    { _id: new Date().setDate(new Date().getDate() - 2), total_count: 200 },
    { _id: new Date().setDate(new Date().getDate() - 1), total_count: 250 }
  ];

  React.useEffect(
    () => {
      axios
        .post(endPoints.getRevenueDetails, {
          token: localStorage.getItem("token"),
          startDate: date.startDate,
          endDate: date.endDate
        })
        .then(r => {
          setChartData(r.data.results.map(d => d.total_count));
          setLabel(
            r.data.results.map(
              d =>
                `${new Date(d._id).getDate()}-${[
                  "Jan",
                  "Feb",
                  "Mar",
                  "Apr",
                  "May",
                  "Jun",
                  "Jul",
                  "Aug",
                  "Sep",
                  "Oct",
                  "Nov",
                  "Dec"
                ][new Date(d._id).getMonth()]}-${new Date(d._id).getFullYear()}`
            )
          );
          setError(false);
        })
        .catch(error => {
          console.error("Error fetching revenue details:", error);
          setChartData(defaultData.map(d => d.total_count));
          setLabel(
            defaultData.map(
              d =>
                `${new Date(d._id).getDate()}-${[
                  "Jan",
                  "Feb",
                  "Mar",
                  "Apr",
                  "May",
                  "Jun",
                  "Jul",
                  "Aug",
                  "Sep",
                  "Oct",
                  "Nov",
                  "Dec"
                ][new Date(d._id).getMonth()]}-${new Date(d._id).getFullYear()}`
            )
          );
          setError(true);
        });
    },
    [date]
  );

  const chartOptions = merge(BaseOptionChart(), {
    stroke: { width: [1, 6, 3], colors: ["#DB6B97"] },
    plotOptions: {
      bar: {
        columnWidth: "21%",
        borderRadius: 4,
        colors: {
          ranges: [
            {
              from: 0,
              to: Number.MAX_SAFE_INTEGER,
              color: "#F47216"
            }
          ],
          backgroundBarOpacity: 1,
          backgroundBarRadius: 0
        }
      }
    },
    fill: { type: ["solid", "gradient", "solid"] },
    labels: label,
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: y => {
          if (typeof y !== "undefined") {
            return `${y.toFixed(0)}`;
          }
          return y;
        }
      }
    }
  });

  return (
    <Card>
      <CardHeader title="Applicants Applied" subheader="date wise" />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart
          series={[
            {
              name: "Applications",
              type: "column",
              data: chartData
            }
          ]}
          options={chartOptions}
          height={364}
        />
      </Box>
    </Card>
  );
}
